import { SQCM_TO_SQM } from './utils';

export const PrintCategory = {
  Blueprint: 'blueprint',
  MonoLines: 'monoLines',
  ColorLines: 'colorLines',
  ColorLowDensityImages: 'colorLowDensityImages',
  ColorHighDensityImages: 'colorHighDensityImages',
  MonoLowDensityImages: 'monoLowDensityImages',
  MonoHighDensityImages: 'monoHighDensityImages',
  Special: 'special',
};

export const UIPrintCategory = {
  Blueprint: 'blueprint',
  MonoLines: 'monoLines',
  ColorLines: 'colorLines',
  LowDensityImage: 'lowDensity',
  HighDensityImage: 'highDensity',
  PremiumQualityImage: 'premium',
};

export const ImpressionsCompletedType = {
  Mono: 'impressionsCompletedMono',
  Color: 'impressionsCompletedColor',
  Total: 'impressionsCompleted',
};

export const FaxType = {
  Sent: 'sentAnalogImages',
  Received: 'receivedAnalogImages',
};

export const getUIPrintCategory = printCategory => {
  switch (printCategory) {
    case PrintCategory.Blueprint:
      return UIPrintCategory.Blueprint;
    case PrintCategory.MonoLines:
      return UIPrintCategory.MonoLines;
    case PrintCategory.ColorLines:
      return UIPrintCategory.ColorLines;
    case PrintCategory.ColorLowDensityImages:
      return UIPrintCategory.LowDensityImage;
    case PrintCategory.ColorHighDensityImages:
      return UIPrintCategory.HighDensityImage;
    case PrintCategory.MonoLowDensityImages:
      return UIPrintCategory.LowDensityImage;
    case PrintCategory.MonoHighDensityImages:
      return UIPrintCategory.HighDensityImage;
    case PrintCategory.Special:
      return UIPrintCategory.PremiumQualityImage;
    default:
      return null;
  }
};

export function getInitialPrintData(range) {
  return {
    blackAndWhite: Array(range).fill(0),
    color: Array(range).fill(0),
    total: 0,
    average: 0,
  };
}

export function getInitialPrintAreaData(range) {
  return Object
    .values(UIPrintCategory)
    .reduce((obj, value) => ({
      ...obj,
      categories: {
        ...obj.categories,
        [value]: Array(range).fill(0),
      },
    }), { categories: {}, average: 0, total: 0 });
}

export function getInitialScanData(range) {
  return {
    counters: [...Array(range).fill(0)],
    total: 0,
    average: 0,
  };
}

export function getInitialFaxData(range) {
  return {
    sent: {
      counters: Array(range).fill(0),
      average: 0,
      total: 0,
    },
    received: {
      counters: Array(range).fill(0),
      average: 0,
      total: 0,
    },
  };
}

const sumArrays = (arr1, arr2) => arr1.map((num, i) => num + arr2[i]);
const fillRestWithNull = (array, totalSize) => [...array, ...Array(totalSize - array.length).fill(null)];

const sumCategoryCounters = (categoryCounters, other) => Object
  .values(UIPrintCategory)
  .reduce((acc, category) => ({
    ...acc,
    [category]: sumArrays(categoryCounters[category], other[category]),
  }), {});

function buildCounters(counters, totalRange, startDate, endDate) {
  const initialCounters = Array(totalRange).fill(0);

  return counters
    .map(({ date, yearMonth, ...rest }) => {
      if (yearMonth) {
        const [yearStr, monthStr] = yearMonth.split('-');
        const year = Number(yearStr);
        const month = monthStr ? Number(monthStr) - 1 : 0;
        const dateInstance = new Date(Date.UTC(year, month, 1));

        return {
          ...rest,
          date: dateInstance,
          yearMonth,
          type: monthStr ? 'monthly' : 'yearly',
        };
      }

      return {
        ...rest,
        date: new Date(date),
        yearMonth,
        type: 'daily',
      };
    })
    .filter(({ date }) => date >= startDate || date <= endDate)
    .sort((a, b) => (a.date < b.date ? -1 : 1))
    .reduce((acc, {
      date, yearMonth, type, ...rest
    }, i) => {
      let index = 0;
      switch (type) {
        case 'daily':
          index = date.getUTCDate() - 1;
          break;
        case 'monthly':
          index = date.getUTCMonth();
          break;
        case 'yearly':
          index = i;
          break;
        default:
      }

      return [
        ...acc.slice(0, index),
        rest ? { date, yearMonth, ...rest } : null,
        ...acc.slice(index + 1),
      ];
    }, initialCounters);
}

export const roundCounter = number => parseFloat(number.toFixed(2));

const convertCounters = (counters, unit) => Object
  .keys(counters)
  .reduce((acc, key) => ({
    ...acc,
    [key]: counters[key].map(counter => roundCounter(counter * unit)),
  }), {});

const getNoDataYet = (hasLastDataReceivedOn, total) => (!hasLastDataReceivedOn && total === 0);

export const buildPrintAreaChartData = ({
  payload,
  totalRange,
  unit = SQCM_TO_SQM,
  rangeToAverage,
  startDate,
  endDate,
}) => {
  const { usageByPrintCategory } = payload;
  let hasLastDataReceivedOn = false;

  const initialPrintAreaData = getInitialPrintAreaData(totalRange);
  const initialCategoryCounters = initialPrintAreaData.categories;

  const summedCounters = usageByPrintCategory?.reduce((categoryCounters, { lastDataReceivedOn, counters }) => {
    hasLastDataReceivedOn = lastDataReceivedOn || hasLastDataReceivedOn;
    const byCategory = counters?.reduce((totalByCategory, { printCategory, finalCounter }) => {
      const uiPrintCategory = getUIPrintCategory(printCategory);

      if (!uiPrintCategory) {
        return totalByCategory;
      }

      const builtCounters = buildCounters(finalCounter || [], totalRange, startDate, endDate)
        .map(counter => (counter ? counter.usedArea : 0));

      return {
        ...totalByCategory,
        [uiPrintCategory]: sumArrays(totalByCategory[uiPrintCategory], builtCounters),
      };
    }, initialCategoryCounters) || initialCategoryCounters;

    return sumCategoryCounters(categoryCounters, byCategory);
  }, initialCategoryCounters) || initialCategoryCounters;

  const convertedCounters = convertCounters(summedCounters, unit);
  const areaTotal = roundCounter(Object
    .values(convertedCounters)
    .reduce((acc, counters) => acc + counters
      .reduce((total, value) => total + value, 0), 0));

  const categories = Object
    .keys(convertedCounters)
    .reduce((acc, key) => ({
      ...acc,
      [key]: fillRestWithNull(convertedCounters[key], totalRange),
    }), {});

  const maxAxisY = Object
    .keys(categories)
    .reduce((acc, key) => acc + Math.max(...categories[key]), 0);

  const { firstUsage } = summedCounters;

  return {
    categories,
    total: areaTotal,
    average: roundCounter(areaTotal / rangeToAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, areaTotal),
    hasLastDataReceivedOn,
    maxAxisY,
    lastDataReceivedOn: new Date(hasLastDataReceivedOn),
    firstUsage,
  };
};

export const buildPrintPagesChartData = ({
  payload,
  totalRange,
  rangeToAverage,
  startDate,
  endDate,
}) => {
  const { printCounters } = payload;
  let hasLastDataReceivedOn = false;

  const zeroedCounters = getInitialPrintData(totalRange);

  const summedCounters = printCounters?.reduce((orgCounters, deviceCounters) => {
    const counters = buildCounters(deviceCounters?.counters || [], totalRange, startDate, endDate);
    hasLastDataReceivedOn = deviceCounters?.lastDataReceivedOn || hasLastDataReceivedOn;

    const accMono = orgCounters.blackAndWhite
      .map((impressionsCompletedMono, i) => impressionsCompletedMono + (
        counters[i]?.impressionsCompletedMono || 0));

    const accColor = orgCounters.color
      .map((impressionsCompletedColor, i) => impressionsCompletedColor + (
        counters[i]?.impressionsCompletedColor || 0));

    const accTotal = orgCounters.total + counters.reduce((acc, cur) => acc + (cur?.impressionsCompleted || 0), 0);

    return {
      blackAndWhite: accMono,
      color: accColor,
      total: accTotal,
      firstUsage: orgCounters.firstUsage ?? counters[0]?.date,
    };
  }, zeroedCounters) || zeroedCounters;

  const {
    blackAndWhite,
    color,
    total,
    firstUsage,
  } = summedCounters;

  const maxAxisY = Math.max(...blackAndWhite) + Math.max(...color);
  const data = {
    blackAndWhite: fillRestWithNull(blackAndWhite, totalRange),
    color: fillRestWithNull(color, totalRange),
    total,
    average: roundCounter(total / rangeToAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, total),
    maxAxisY,
    hasLastDataReceivedOn,
    lastDataReceivedOn: new Date(hasLastDataReceivedOn),
    firstUsage,
  };

  return data;
};

export const buildScanChartData = ({
  payload,
  totalRange,
  rangeToAverage,
  startDate,
  endDate,
}) => {
  const { scanCounters } = payload;
  const zeroedCounters = getInitialScanData(totalRange);
  let hasLastDataReceivedOn = false;

  const summedCounters = scanCounters?.reduce((orgCounters, deviceCounters) => {
    hasLastDataReceivedOn = deviceCounters?.lastDataReceivedOn || hasLastDataReceivedOn;
    const counters = buildCounters(deviceCounters?.counters || [], totalRange, startDate, endDate);
    const values = counters.map(counter => (counter ? counter.totalImages : 0));

    return {
      counters: sumArrays(orgCounters.counters, values),
      firstUsage: orgCounters.firstUsage ?? counters[0]?.date,
    };
  }, zeroedCounters) || zeroedCounters;

  const {
    counters,
    firstUsage,
  } = summedCounters;

  const total = counters.reduce((acc, cur) => acc + cur, 0);
  const maxAxisY = Math.max(...counters);
  const data = {
    counters: fillRestWithNull(counters, totalRange),
    total,
    average: roundCounter(total / rangeToAverage),
    noDataYet: getNoDataYet(hasLastDataReceivedOn, total),
    maxAxisY,
    hasLastDataReceivedOn,
    lastDataReceivedOn: new Date(hasLastDataReceivedOn),
    firstUsage,
  };

  return data;
};

export const buildFaxChartData = ({
  payload,
  totalRange,
  rangeToAverage,
  startDate,
  endDate,
}) => {
  const { faxCounters } = payload;
  const zeroedCounters = getInitialFaxData(totalRange);
  let hasLastDataReceivedOn = false;

  const summedCounters = faxCounters?.reduce((orgCounters, deviceCounters) => {
    const counters = buildCounters(deviceCounters?.counters || [], totalRange, startDate, endDate);
    hasLastDataReceivedOn = deviceCounters?.lastDataReceivedOn || hasLastDataReceivedOn;

    const accReceived = orgCounters.received.counters
      .map((received, i) => received + (counters[i]?.[FaxType.Received] || 0));

    const accSent = orgCounters.sent.counters
      .map((sent, i) => sent + (counters[i]?.[FaxType.Sent] || 0));

    return {
      sent: { counters: accSent },
      received: { counters: accReceived },
      firstUsage: orgCounters.firstUsage ?? counters[0]?.date,
    };
  }, zeroedCounters) || zeroedCounters;

  const {
    sent,
    received,
    firstUsage,
  } = summedCounters;

  const totalSent = sent.counters.reduce((acc, cur) => acc + cur, 0);
  const totalReceived = received.counters.reduce((acc, cur) => acc + cur, 0);
  const maxAxisY = Math.max(...received.counters) + Math.max(...sent.counters);

  const data = {
    sent: {
      counters: fillRestWithNull(sent.counters, totalRange),
      average: roundCounter(totalSent / rangeToAverage),
      total: totalSent,
    },
    received: {
      counters: fillRestWithNull(received.counters, totalRange),
      average: roundCounter(totalReceived / rangeToAverage),
      total: totalReceived,
    },
    noDataYet: getNoDataYet(hasLastDataReceivedOn, (totalSent + totalReceived)),
    maxAxisY,
    hasLastDataReceivedOn,
    lastDataReceivedOn: new Date(hasLastDataReceivedOn),
    firstUsage,
  };

  return data;
};

export const getNumMonthsAverage = ({
  selectedYear,
  createOrgDate,
  currentDate,
}) => {
  const yearCreateOrg = createOrgDate.getFullYear();
  const monthCreateOrg = createOrgDate.getMonth();

  if (yearCreateOrg === selectedYear) {
    if (currentDate.getFullYear() === selectedYear) {
      return (currentDate.getMonth() + 1) - monthCreateOrg;
    }
    return 12 - monthCreateOrg;
  }

  if (currentDate.getFullYear() === selectedYear) {
    return currentDate.getMonth() + 1;
  }

  return 12;
};

export const getNumDaysAverage = ({
  selectedYear,
  selectedMonth,
  createOrgDate,
  currentDate,
}) => {
  const yearCreateOrg = createOrgDate.getFullYear();
  const monthCreateOrg = createOrgDate.getMonth();
  const dayCreateOrg = createOrgDate.getDate();
  const daysInCurrentMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

  if (yearCreateOrg === selectedYear && monthCreateOrg === selectedMonth) {
    if (currentDate.getMonth() === selectedMonth) {
      return currentDate.getDate() - dayCreateOrg + 1;
    }

    return daysInCurrentMonth - dayCreateOrg + 1;
  }

  if (currentDate.getMonth() === selectedMonth) {
    return currentDate.getDate();
  }

  return daysInCurrentMonth;
};
