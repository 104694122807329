import React from 'react';
import PropTypes from 'prop-types';
import { getStatusWithId } from './statusDictionary';
import DeviceStatusBase from './DeviceStatusBase';

const DeviceStatus = ({
  group,
  onClick,
  status,
  withCoachmark,
  withExplanation,
  ...rest
}) => {
  const statusWithId = getStatusWithId(status, { group });
  const dataTestId = rest['data-testid'] || status;
  if (statusWithId) {
    return (
      <DeviceStatusBase
        data-testid={dataTestId}
        {...rest}
        {...statusWithId}
        onClick={onClick}
        withCoachmark={withCoachmark}
        withExplanation={withExplanation}
      />
    );
  }
  return null;
};

DeviceStatus.defaultProps = {
  group: undefined,
  onClick: null,
  withCoachmark: false,
  withExplanation: false
};

DeviceStatus.propTypes = {
  group: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.string.isRequired,
  withCoachmark: PropTypes.bool,
  withExplanation: PropTypes.bool
};

export default DeviceStatus;
