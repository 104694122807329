import { useCallback } from 'react';
import { RelationshipsClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useRelationshipsApiCall({ authProvider, stack }) {
  const apiCall = useCallback(
    async ({ parentOrg, parentOrgName }) => {
      const client = new RelationshipsClient(stack, authProvider);
      const nodes = await client.getAllPersonNodes();
      if (!nodes || nodes.status !== 200) {
        return;
      }
      const list = [];
      nodes.data.content.forEach(async (nodeId) => {
        const node = await client.getNode({ nodeId });
        if (!node || node.status !== 200) {
          return;
        }
        list.push({
          value: nodeId,
          label: nodeId === parentOrg ? parentOrgName : node.data.accountName,
          otherData: node.data,
          disabled: nodeId === parentOrg
        });
      });

      return list;
    },
    [authProvider, stack]
  );

  return useApiCall({
    apiCall,
    init: false
  });
}
