import React from 'react';
import { Modal } from './Modal';
import { Button, IconMinusCircle, ButtonGroup } from '@veneer/core';
import { BodyContainer, BodyItem, HeaderContainer, HeaderText } from './styles';
import { useI18n } from '@jarvis/react-portal-addons';

const TransferErrorModal = ({ onCloseModal, openModal }) => {
  const { t } = useI18n();
  return (
    <Modal
      id="regular-modal"
      data-testid="invite-users-modal"
      onClose={onCloseModal}
      show={openModal}
      footer={
        <ButtonGroup>
          <Button onClick={onCloseModal}>
            {t('myPrinters.transferOwnershipModal.failed.primaryButton')}
          </Button>
        </ButtonGroup>
      }
    >
      <HeaderContainer>
        <IconMinusCircle
          filled
          color="colorRed6"
          size={40}
        />
        <HeaderText className="title-small">
          {t('myPrinters.transferOwnershipModal.failed.header')}
        </HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.failed.body1')}
        </BodyItem>
      </BodyContainer>
    </Modal>
  );
};

export default TransferErrorModal;
