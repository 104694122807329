import React from 'react';
import { Modal } from './Modal';
import {
  HeaderContainer,
  HeaderText,
  CardNote,
  CardNoteContent,
  CardNoteText,
  BodyContainer,
  BodyItem
} from './styles';
import {
  ButtonGroup,
  IconCheckCircle,
  Button,
  IconWarning as IconWarningCircle
} from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';

const TransferSuccessModal = ({
  openModal,
  onCloseModal,
  selectedOrganizationName
}) => {
  const { t } = useI18n();
  return (
    <Modal
      id="regular-modal"
      data-testid="transfer-success-modal"
      onClose={onCloseModal}
      show={openModal}
      footer={
        <ButtonGroup>
          <Button
            appearance="secondary"
            onClick={onCloseModal}
          >
            {t(
              'myPrinters.transferOwnershipModal.transferSuccess.secondaryButton'
            )}
          </Button>
          <Button onClick={onCloseModal}>
            {t(
              'myPrinters.transferOwnershipModal.transferSuccess.primaryButton'
            )}
          </Button>
        </ButtonGroup>
      }
    >
      <HeaderContainer>
        <IconCheckCircle
          filled
          color="colorGreen6"
          size={40}
        />
        <HeaderText className="title-small">
          {t('myPrinters.transferOwnershipModal.transferSuccess.header')}
        </HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.transferSuccess.body1', {
            accountName: selectedOrganizationName
          })}
        </BodyItem>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.transferSuccess.body2')}
        </BodyItem>
      </BodyContainer>
      <CardNote
        appearance="outlined"
        content={
          <CardNoteContent>
            <IconWarningCircle
              filled
              color="colorGrey8"
              size={24}
            ></IconWarningCircle>
            <CardNoteText>
              <h3 className="subtitle-regular">
                {t(
                  'myPrinters.transferOwnershipModal.transferSuccess.card.header'
                )}
              </h3>
              <h5 className="label-small">
                {t(
                  'myPrinters.transferOwnershipModal.transferSuccess.card.body1'
                )}
              </h5>
            </CardNoteText>
          </CardNoteContent>
        }
      />
    </Modal>
  );
};

export default TransferSuccessModal;
