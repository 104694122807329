import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const CoachContainer = styled.span`
  display: flex;
  align-items: center;

  && {
    margin: 0;
    z-index: 1;

    svg {
      cursor: pointer;
      margin: 0;
      padding: 0;
    }
  }
  + div > div:nth-child(1) {
    > div span {
      font-size: ${tokens.fontSize1};
      line-height: ${tokens.fontSize3};
      color: ${tokens.colorGray3};
    }
  }
`;

export default CoachContainer;
