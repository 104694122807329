export const parseDate = (dateString) => {
  if (!dateString) {
    return null;
  }
  const date = new Date(dateString);
  return date.toLocaleString();
};

export const epochToDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString();
};
