import { isAndroid, isIOS, isMobile } from 'react-device-detect';

const jWeb = window.JWeb;

const JWEB_EVENTS = {
  CLOSE: 'Close',
  JARVIS_FINISHED: 'jarvisEventFinished'
};

export const isJWebAuthPluginValid = () => jWeb?.Plugins?.Auth;

export const isJWebDesktopApp = () =>
  !isMobile && isJWebAuthPluginValid() && jWeb?.isNative;

export const isJWebiOSApp = () =>
  isIOS && isJWebAuthPluginValid() && jWeb?.isNative;

export const isJWebAndroidApp = () =>
  isAndroid && isJWebAuthPluginValid() && jWeb?.isNative;

export const isJWebApp = () =>
  isJWebDesktopApp() || isJWebAndroidApp() || isJWebiOSApp();

export const isJWebEventingPluginValid = () => jWeb?.Plugins?.Eventing;

export const dispatchJWebCloseEvent = () => {
  const Eventing = isJWebEventingPluginValid();
  if (!Eventing) {
    console.error('JWeb.Plugins.Eventing is not valid.');
    return;
  }

  try {
    Eventing.dispatchEvent({ name: JWEB_EVENTS.JARVIS_FINISHED, data: {} });
    Eventing.dispatchEvent({ name: JWEB_EVENTS.CLOSE, data: {} });
  } catch (err) {
    console.error(`Error dispatching close events to JWeb:\n${err}`);
  }
};
