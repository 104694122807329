import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Card as VeneerCard } from '@veneer/core';
import Images from 'src/assets/images';
import { Link } from '@jarvis/react-portal-addons';

export const MainCard = styled(VeneerCard)`
  border-color: ${tokens.colorGray2};
  && {
    border-radius: 0;
    border-width: 1px 0 0;
  }
`;

export const BannerCard = styled(VeneerCard)`
  height: 100%;
  margin: 24px 32px;
  border-color: ${tokens.colorGray2};
  @media (max-width: ${tokens.xsMax}) {
    // ucde-portal: 600px
    margin: 20px 4px;
  }
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
`;

export const LogoHp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${tokens.borderRadius4} 0 0 ${tokens.borderRadius4};
  background-image: url(${Images.hpSecondOffer});
  background-repeat: no-repeat;
  background-position: right center;
  min-width: 109px;
  min-height: 100%;
  background-size: auto 100%;

  @media (max-width: ${tokens.xsMax}) {
    min-width: 70px;
  }

  > img {
    width: 53px;
    height: 40px;

    @media (max-width: ${tokens.xsMax}) {
      transform: scale(0.7);
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: center;
  font-size: 16px;
  color: ${tokens.colorGray10};
  line-height: 1.5;

  @media (max-width: ${tokens.lgMax}) {
    // ucde-portal: 1036px
    font-size: 14px;
  }

  @media (max-width: ${tokens.xsMax}) {
    margin: 5px;
    margin-left: 10px;
    && {
      div,
      a {
        font-size: 12px;
      }
    }
  }
`;

export const Text1 = styled.div`
  font-family: ${tokens.fontFamilyLight};
`;

export const Text2 = styled.div`
  font-family: ${tokens.fontFamilyRegular};
`;

export const LearnMoreButton = styled(Link)`
  display: block;
  padding: 0;
  font-family: ${tokens.fontFamilyRegular};
  font-size: 16px;
  line-height: 26px;

  :hover && {
    background-color: transparent;
    color: ${tokens.colorHpBlue6};
  }

  @media (max-width: ${tokens.lgMax}) {
    && {
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const StyledLink = styled(Link)`
  margin-left: auto;
  margin-right: 20px;
  align-self: center;
  @media (max-width: ${tokens.xsMax}) {
    margin-right: 5px;
  }

  && {
    padding: 0;
    color: ${tokens.colorGray7};
    :hover {
      background-color: transparent;
      color: ${tokens.colorGray7};
    }
  }
`;
