import styled from 'styled-components';

export const PrinterDetailContainer = styled.div`
  @media (min-width: 1036px) {
    margin-left: 14px;
  }

  padding: 26px 0 18px;

  &:first-of-type {
    padding-top: 8px;
  }

  &:not(:first-of-type) {
    border-top: 1px solid #e9e5e5;
  }
`;
