export function buildDate({
  year,
  month,
  day,
}) {
  let monthStr = `${month}`;
  let dayStr = `${day}`;

  if (month < 10) {
    monthStr = `0${month}`;
  }

  if (day < 10) {
    dayStr = `0${day}`;
  }

  return `${year}-${monthStr}-${dayStr}`;
}

export function getEndDate(selectedYear, currentDate) {
  if (selectedYear === currentDate.getFullYear()) {
    return currentDate.toISOString().split('T')[0];
  }

  return `${selectedYear}-12-31`;
}

export const SQCM_TO_SQFT = 0.00107639;
export const SQCM_TO_SQM = 0.0001;

export const STORAGE_COLUMN_DATA_USAGE = 'dashboard-column-detail-data-usage';

export const generalTooltip = {
  useHTML: true,
  backgroundColor: '#2B2B2B',
  style: {
    color: '#FFF',
  },
  borderRadius: 12,
  padding: 8,
  fontSize: 16,
  cursor: 'pointer',
};

export const getPointsWithoutAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage !== true);
export const getPointsAverage = points => points.filter(p => p.series.userOptions?.isTooltipAverage === true);
export const getPointSelected = points => points?.find(p => p.series.state === 'hover');

export const buildTooltipTitleMapping = (xAxisCategories, tooltipCategories) => tooltipCategories
  .reduce((acc, cur, i) => ({
    ...acc,
    [xAxisCategories[i]]: cur,
  }), {});

export const getYearlyTotalRange = ({ firstUsageDate, currentDate }) => {
  if (!firstUsageDate) {
    return 1;
  }

  return currentDate.getUTCFullYear() - firstUsageDate.getUTCFullYear() + 1;
};

export const getTooltipAverage = point => {
  const { selectedYear, name } = point.series.userOptions;

  return `<b>${selectedYear}</b> <br/> ${name}: ${point.y}`;
};

export const formatTooltipAverageTitle = title => `\
    <p class="label">\
      <b>${title}</b>\
    </p>`;

export const formatTooltipAverageBody = (category, amount, unit) => `\
    <p class="label-small">\
      ${category}: ${amount} ${unit}\
    </p>`;

export const getTooltipGeneralAverage = (pointsAverage, isDeviceSupportTouch) => {
  let textTooltip = '';
  if (isDeviceSupportTouch && pointsAverage && pointsAverage.length > 0) {
    textTooltip = `${getTooltipAverage(pointsAverage[0])}<br/><br/>`;
  }
  return textTooltip;
};

export function formatTooltipTitle(title) {
  return `<p class="label"><b>${title}</b></p>`;
}

export function formatTooltipBody(category, amount, unit) {
  return `\
    <p class="label-small">\
      ${category}: ${amount} ${unit}\
    </p>\
  `;
}

export function formatBulletListItem(category, amount, unit) {
  return `\
    <p class="label-small">\
      &nbsp; &nbsp; &#x2022; ${category}: ${amount} ${unit}\
    </p>\
  `;
}

export function formatAverageTooltip({
  chart,
  i18n,
  showUnit,
}) {
  const pointSelected = chart.point;
  const titleStr = i18n.tooltip.averageTitleFormatter(pointSelected);
  const category = pointSelected.series.name;

  return `\
    ${formatTooltipAverageTitle(titleStr)}\
    ${formatTooltipAverageBody(category, pointSelected.y, showUnit ? i18n.tooltip.unit : '')}\
  `;
}

export function formatTooltipList({
  chart,
  i18n,
  isDeviceTouch,
  defaultTitle,
  legendTitles,
  showUnit,
  showTotal,
  reverseOrder,
}) {
  const pointsAverage = getPointsAverage(chart?.points);
  const totalUnitStr = showUnit ? (i18n.tooltip.totalUnit ?? i18n.tooltip.unit) : '';
  const unitStr = showUnit ? i18n.tooltip.unit : '';
  const { titleFormatter } = i18n.tooltip;

  const totalStr = (showTotal && chart.points.length > 2)
    ? `${formatTooltipBody(i18n.tooltip.total, chart.points[1].total, totalUnitStr)}`
    : '';

  const titleStr = `\
    ${formatTooltipTitle(titleFormatter ? titleFormatter(defaultTitle) : defaultTitle)}\
    ${totalStr}\
  `;

  const points = getPointsWithoutAverage(chart.points);

  if (reverseOrder) {
    points.reverse();
  }

  return getTooltipGeneralAverage(pointsAverage, isDeviceTouch)
    + points
      .reduce((accStr, point) => {
        const category = legendTitles[point.series.userOptions.id];
        const amount = point.y;

        const listItemStr = chart.points.length > 2
          ? formatBulletListItem(category, point.y, unitStr)
          : formatTooltipAverageBody(category, amount, unitStr);

        return `${accStr}${listItemStr}`;
      },
      titleStr);
}

export function tooltipFormatter({
  chart,
  i18n,
  defaultTitle,
  isDeviceTouch,
  legendTitles,
  showUnit = true,
  showAverageUnit = true,
  showTotal = true,
  reverseOrder = true,
}) {
  const pointSelected = chart.point ?? getPointSelected(chart.points);

  if (!!pointSelected && pointSelected.series.userOptions?.isTooltipAverage === true) {
    return formatAverageTooltip({
      chart,
      i18n,
      showUnit: showAverageUnit,
    });
  }

  return formatTooltipList({
    chart,
    i18n,
    isDeviceTouch,
    defaultTitle,
    legendTitles,
    showUnit,
    showAverageUnit,
    showTotal,
    reverseOrder,
  });
}
