import React, { useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import ErrorWidget from '../ErrorWidget';
import LoaderWidget from '../LoaderWidget';

import {
  Container,
  LoaderWrapper,
} from './styles';

const EmptyCard = () => (
  <LoaderWrapper appearance="dropShadow" content={<LoaderWidget />} />
);

const MicroFrontend = ({
  component,
  card,
  onErrorBackClick,
  onErrorRetryClick,
  onLoad,
  ...rest
}) => {
  const Component = useMemo(() => React.lazy(() => System.import(component)), [component]);

  useEffect(() => {
    onLoad?.();
  }, [onLoad]);

  return (
    <Container>
      {component ? (
        <ErrorBoundary
          fallback={(
            <ErrorWidget
              onBack={onErrorBackClick}
              onRetry={onErrorRetryClick}
            />
          )}
        >
          <React.Suspense fallback={card ? <EmptyCard /> : <LoaderWidget fullScreen />}>
            <Component {...rest} />
          </React.Suspense>
        </ErrorBoundary>
      ) : (
        <LoaderWrapper appearance="dropShadow" />
      )}
    </Container>
  );
};

MicroFrontend.propTypes = {
  card: PropTypes.bool,
  component: PropTypes.string.isRequired,
  onErrorBackClick: PropTypes.func,
  onErrorRetryClick: PropTypes.func,
  onLoad: PropTypes.func,
};

MicroFrontend.defaultProps = {
  card: false,
  onErrorBackClick: null,
  onErrorRetryClick: null,
  onLoad: null,
};

export default MicroFrontend;
