import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionHeaderText } from './styles';

const PrinterAccordion = ({ id, headerText, content, dataTestId }) => {
  return (
    <Accordion
      data-testid={dataTestId}
      behavior="singleExpand"
      items={[
        {
          id,
          header: {
            centralArea: (
              <AccordionHeaderText
                data-testid={id}
                className="body-large"
              >
                {headerText}
              </AccordionHeaderText>
            )
          },
          ...(content && { content: content })
        }
      ]}
    />
  );
};

PrinterAccordion.defaultProps = {
  content: null
};

PrinterAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  content: PropTypes.node
};

export default PrinterAccordion;
