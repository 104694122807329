import { useCallback } from 'react';
import { FirmwareUpdateConfiguration } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import pkg from '../../package.json';

const defaultResponse = {
  availableDeviceUpdateTypes: [],
  fwUpdateConfiguration: { updateType: '' },
  hasShadowSyncCapabilities: true
};

export default function useGetFwUpdCfgApiCall({
  authProvider,
  stack,
  deviceUuid
}) {
  const apiCall = useCallback(async () => {
    if (deviceUuid === undefined || deviceUuid === null || deviceUuid === '') {
      return defaultResponse;
    }
    const client = new FirmwareUpdateConfiguration(stack, authProvider);
    try {
      const response = await client.getFirmwareUpdateConfig(
        deviceUuid,
        true,
        {
          app: pkg.name,
          platform: 'consumer-shell',
          version: pkg.version
        },
        true
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 404) {
        return defaultResponse;
      }
      throw error;
    }
  }, [authProvider, deviceUuid, stack]);

  return useApiCall({ apiCall });
}
