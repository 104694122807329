import { useCallback } from 'react';
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useDeviceProgramInfosApiCall({
  authProvider,
  stack,
  deviceId,
  accountId
}) {
  const apiCall = useCallback(async () => {
    const client = new AccountMgtSvcClient(stack, authProvider);
    const response = await client.getDeviceProgramInfos(accountId, deviceId);
    return response.data?.programInfo;
  }, [authProvider, stack, deviceId, accountId]);

  return useApiCall({ apiCall });
}
