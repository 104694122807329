import React from 'react';
import PropTypes from 'prop-types';
import DeviceStatus from './DeviceStatus';
import { DetailContainer } from './styles';
import { STATUS } from './statusDictionary';

const DeviceStatusDetail = ({
  status,
  group,
  onClick,
  withExplanation,
  ...rest
}) => {
  return (
    <DetailContainer>
      <DeviceStatus
        {...rest}
        group={group}
        onClick={onClick}
        status={status}
        withExplanation={withExplanation}
      />
    </DetailContainer>
  );
};

DeviceStatusDetail.defaultProps = {
  group: STATUS.GROUP.general,
  onClick: null,
  withExplanation: true
};

DeviceStatusDetail.propTypes = {
  status: PropTypes.string.isRequired,
  group: PropTypes.string,
  onClick: PropTypes.func,
  withExplanation: PropTypes.bool
};

export default DeviceStatusDetail;
