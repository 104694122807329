import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Images from 'src/assets/images';
import {
  PrinterInfo,
  PrinterItem,
  PrinterName,
  PrinterImage,
  PrinterStatus
} from './styles';
import DeviceStatus from 'src/components/DeviceStatus/DeviceStatus';
import { STATUS } from 'src/components/DeviceStatus/statusDictionary';

export const AccordionHeaderPrinter = ({ device }) => {
  const genericImage = Images.genericPrinter;
  const [printerImage, setPrinterImage] = useState(genericImage);

  useEffect(() => {
    if (device?.images?.length > 0) {
      setPrinterImage(device.images[device.images.length - 1]?.url);
    } else {
      setPrinterImage(genericImage);
    }
  }, [device?.images, genericImage]);

  return (
    <PrinterItem key={`${device.deviceId}`}>
      <PrinterImage
        src={printerImage.toString()}
        alt={device?.identity?.makeAndModel?.name}
        onError={() => setPrinterImage(genericImage)}
      />
      <PrinterInfo>
        <PrinterName>
          {device?.identity?.makeAndModel?.name.replace(/_/g, ' ')}
        </PrinterName>
        <PrinterStatus>
          <DeviceStatus
            group={STATUS.GROUP.healthTenant}
            status={device?.printerHealth}
            withCoachmark
          />
        </PrinterStatus>
      </PrinterInfo>
    </PrinterItem>
  );
};

AccordionHeaderPrinter.propTypes = {
  device: PropTypes.shape({
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string })),
    deviceId: PropTypes.string.isRequired,
    identity: PropTypes.shape({
      makeAndModel: PropTypes.shape({
        name: PropTypes.string
      })
    }),
    printerHealth: PropTypes.string.isRequired
  }).isRequired
};

export default AccordionHeaderPrinter;
