import { useCallback } from 'react';
import { DeviceTransferClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useDeviceEligibilityApiCall({
  authProvider,
  stack,
  userId,
  deviceId,
  orgGroupId
}) {
  const apiCall = useCallback(async () => {
    if (!userId || !deviceId || !orgGroupId) {
      return null;
    }
    const client = new DeviceTransferClient(stack, authProvider);
    const options = {
      userId,
      deviceId,
      orgGroupId
    };
    const response = await client.getDeviceEligibility(options);
    return response.data;
  }, [authProvider, deviceId, orgGroupId, stack, userId]);

  return useApiCall({ apiCall });
}
