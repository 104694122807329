import React, { useMemo } from 'react';
import { useRootContext, useI18n } from '@jarvis/react-portal-addons';
import { epochToDate } from 'src/utils/date';
import {
  BannerCard,
  Container,
  ContainerText,
  LearnMoreButton,
  LogoHp,
  MainCard,
  StyledLink,
  Text1,
  Text2
} from './styles';
import Images from 'src/assets/images';
import { IconChevronRight } from '@veneer/core';
import PATHS from 'src/constants/paths';
import { publishHyperLinkClickedEvent } from 'src/utils/analytics';

const SecondOffer = ({ device, expires }) => {
  const { t } = useI18n();
  const { analytics } = useRootContext();

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  const registerClickEvent = () => {
    publishHyperLinkClickedEvent(
      analytics,
      'SecondOfferLearnMore',
      device?.identity?.makeAndModel?.name,
      'SecondOfferCard',
      analyticsMetadata
    );
  };

  const Card = () => (
    <Container>
      <LogoHp>
        <img
          alt="HP+"
          src={Images.hpPlusLogoWhite}
        />
      </LogoHp>
      <ContainerText>
        <Text1>{t('hpSecondChance.bodyCopy.text1')}</Text1>
        <Text2>
          {t('hpSecondChance.bodyCopy.text2', {
            expirationDate: epochToDate(expires),
            interpolation: { escapeValue: false }
          })}
        </Text2>
        <LearnMoreButton
          href={`${PATHS.HP_PLUS_SECOND_OFFER}?cloudId=${device.deviceId}`}
          onClick={registerClickEvent}
        >
          {t('hpSecondChance.link')}
        </LearnMoreButton>
      </ContainerText>
      <StyledLink
        href={`${PATHS.HP_PLUS_SECOND_OFFER}?cloudId=${device.deviceId}`}
        onClick={registerClickEvent}
      >
        <IconChevronRight />
      </StyledLink>
    </Container>
  );
  const CardSecond = () => (
    <BannerCard
      content={<Card />}
      appearance="outlined"
    />
  );
  return (
    <MainCard
      content={<CardSecond />}
      appearance="outlined"
    />
  );
};

export default SecondOffer;
