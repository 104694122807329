import React from 'react';
import { Modal } from './Modal';
import { BodyContainer, BodyItem, HeaderContainer, HeaderText } from './styles';
import { ButtonGroup, Button, IconWarning } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';

const LastPrinterModal = ({ openModal, handleCloseModal, onContinue }) => {
  const { t } = useI18n();
  return (
    <Modal
      id="last-printer"
      data-testid="last-printer-modal"
      onClose={handleCloseModal}
      show={openModal}
      footer={
        <ButtonGroup>
          <Button
            appearance="secondary"
            onClick={handleCloseModal}
          >
            {t(
              'myPrinters.transferOwnershipModal.warningLosingBenefits.secondaryButton'
            )}
          </Button>
          <Button onClick={onContinue}>
            {t(
              'myPrinters.transferOwnershipModal.warningLosingBenefits.primaryButton'
            )}
          </Button>
        </ButtonGroup>
      }
    >
      <HeaderContainer>
        <IconWarning
          filled
          color="colorOrange6"
          size={40}
        />
        <HeaderText className="title-small">
          {t('myPrinters.transferOwnershipModal.warningLosingBenefits.header')}
        </HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.warningLosingBenefits.body1')}
        </BodyItem>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.warningLosingBenefits.body2')}
        </BodyItem>
      </BodyContainer>
    </Modal>
  );
};

export default LastPrinterModal;
