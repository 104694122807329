import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Link } from '@jarvis/react-portal-addons';

export const StyledLinkButton = styled.div`
  && {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    :hover {
      color: ${tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${tokens.colorHpBlue8};
      text-decoration: underline;
    }
    cursor: pointer;
    font-size: ${tokens.fontSize2};
    @media (min-width: 1036px) {
      font-size: ${tokens.fontSize3};
    }
    padding: 0 0 0 20px;
    line-height: 28px;
    @media (min-width: ${tokens.lgMin}) {
      padding: 0 0 0 48px;
      line-height: 32px;
    }
  }
`;

export const StyledLink = styled(Link)`
  && {
    display: block;
    font-size: ${tokens.fontSize2};
    @media (min-width: 1036px) {
      font-size: ${tokens.fontSize3};
    }
    padding: 0 0 0 20px;
    line-height: 28px;
    @media (min-width: ${tokens.lgMin}) {
      padding: 0 0 0 48px;
      line-height: 32px;
    }
  }
`;
