import styled from 'styled-components';
import { Accordion } from '@veneer/core';
import { isMobile } from 'react-device-detect';
import tokens from '@veneer/tokens';

export const PrintersAccordion = styled(Accordion)`
  padding-left: ${isMobile ? '2px' : ''};
  padding-right: ${isMobile ? '2px' : ''};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding: 16px 24px 8px 24px;

  h5 {
    font-size: ${tokens.fontSize5};
    margin-left: 6px;
    width: 100%;
  }

  > .refresh-button {
    justify-content: flex-end;
  }
`;

export const NoPrinters = styled.div`
  && {
    font-size: 12px;
    padding: 32px 16px;
    text-align: center;

    @media (min-width: ${tokens.lgMin}) and (max-width: ${tokens.xlMax}) {
      font-size: 16px;
      padding: 32px;
    }
  }
`;

export const NoPrintersIcon = styled.img`
  display: block;
  margin: auto auto 32px;
`;
