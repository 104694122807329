import { useCallback } from 'react';
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useAccountInfoApiCall({ authProvider, stack }) {
  const fetch = useCallback(async () => {
    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    const response = await accountMgtSvcClient.getAccount();
    return response.data;
  }, [authProvider, stack]);

  return useApiCall({ fetch });
}
