import React from 'react';
import PropTypes from 'prop-types';
import { DetailContainer } from './styles';
import DeviceStatusBase from './DeviceStatusBase';

const DeviceReasonDetail = ({ reason, withExplanation, ...rest }) => {
  const dataTestId = rest['data-testid'] || reason?.status;
  return (
    <DetailContainer>
      <DeviceStatusBase
        data-testid={dataTestId}
        {...rest}
        {...reason}
        withExplanation={withExplanation}
      />
    </DetailContainer>
  );
};

DeviceReasonDetail.defaultProps = {
  colorCodes: [],
  formatParams: null,
  message: null,
  withExplanation: true
};

DeviceReasonDetail.propTypes = {
  reason: PropTypes.shape({
    colorCodes: PropTypes.arrayOf(PropTypes.object),
    formatParams: PropTypes.object,
    Icon: PropTypes.object.isRequired,
    message: PropTypes.string,
    title: PropTypes.string.isRequired,
    withExplanation: PropTypes.bool
  }).isRequired
};

export default DeviceReasonDetail;
