import React, { useCallback } from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';

export default function useDeviceApiCall({ authProvider, stack, deviceId }) {
  const { featureFlags } = useRootContext();
  const useDeviceCache = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'useDeviceCache',
      defaultValue: false
    }
  );
  const { getDevice } = useDeviceApiAsDeviceCache({
    authProvider,
    stack
  });

  const apiCall = useCallback(async () => {
    if (!deviceId) {
      return null;
    }
    let response = null;
    if (useDeviceCache) {
      const client = new DeviceCacheApiClient(stack, authProvider);
      response = await client.show(deviceId);
    } else {
      response = await getDevice(deviceId);
    }
    return response.data;
  }, [authProvider, stack, deviceId, getDevice, useDeviceCache]);

  return useApiCall({ apiCall });
}
