import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import { JarvisWebHttpClient } from '@jarvis/web-http';
import { getCecUrl } from 'src/utils/utils';

export default function useSecondChanceEligibilityApiCall({
  authProvider,
  stack,
  deviceId,
  platformIdentifier
}) {
  const apiCall = useCallback(async () => {
    if (
      !deviceId ||
      platformIdentifier === 'gen1' ||
      platformIdentifier === 'gen2'
    ) {
      return { eligible: false, expires: 0 };
    }
    const baseUrl = getCecUrl(stack);
    const client = new JarvisWebHttpClient({
      defaultBaseURL: baseUrl,
      defaultAuthProvider: authProvider
    });
    const options = {
      url: `/1/api/campaigns/hp-plus?cloudId=${deviceId}`
    };
    try {
      const response = await client.get(options);
      return response.data;
    } catch (error) {
      return {
        eligible: false,
        expires: 0
      };
    }
  }, [authProvider, deviceId, platformIdentifier, stack]);

  return useApiCall({ apiCall });
}
