const genericPrinter = require('./generic-printer.svg');
const hpPlusLogoWhite = require('./hp-plus-logo-white.svg');
const hpSecondOffer = require('./hp-second-offer.svg');
const benefitsCloudPrint = require('./benefits-cloudPrint.svg');
const benefitsShield = require('./benefits-shield.svg');

const Images = {
  genericPrinter,
  hpPlusLogoWhite,
  hpSecondOffer,
  benefitsCloudPrint,
  benefitsShield
};

export default Images;
