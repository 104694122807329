import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${tokens.smMax}) {
    flex-direction: row-reverse;
  }
`;

export const Message = styled.span`
  color: ${tokens.colorGray6};
  font-size: ${tokens.fontSize1};
  white-space: nowrap;
  margin-left: 10px;

  @media (max-width: ${tokens.smMax}) {
    margin-left: 10px;
    text-align: left;
    white-space: normal;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  padding: 1px 6px;
  height: 26px;
  div {
    height: inherit;
  }
`;

export const Button = styled.button`
  && {
    font-size: ${tokens.fontSize1};
    display: inline-flex;
    align-items: center;
    border: 0;
    color: ${tokens.colorHpBlue6};
    background: transparent;

    > svg {
      fill: ${tokens.colorHpBlue6};
    }

    :hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }

    @media (min-width: ${tokens.lgMin}) {
      font-size: ${tokens.fontSize3};
    }
  }
`;
