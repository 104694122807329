import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useI18n } from '@jarvis/react-portal-addons';
import { ServiceItem } from './ServiceItem';
import Supplies from 'src/components/Supplies';
import Images from 'src/assets/images';
import { STATUS } from 'src/components/DeviceStatus/statusDictionary';
import { getStateReasons } from 'src/components/DeviceStatus/stateReasons';
import DeviceStatus from 'src/components/DeviceStatus/DeviceStatus';
import DeviceReasonDetail from 'src/components/DeviceStatus/DeviceReasonDetail';
import {
  CartridgeContainer,
  Container,
  LeftArea,
  RightArea,
  RightFooter,
  Separator,
  ServicesHeader,
  StatusContainer,
  StatusHeader,
  SuppliesContent
} from './styles';

const DropdownItem = ({ device }) => {
  const { t } = useI18n();

  const printerStateReasons = useMemo(
    () => getStateReasons(device)?.sort((a, b) => a.type > b.type),
    [device]
  );
  const isDisconnected =
    device?.status?.connectionState === STATUS.CONNECTION.offline;
  const hasPrintOnTheGo = device?.hasPrintOnTheGo;
  const hasSmartSecurity = device?.securityAssessmentResult;
  const hasFeatures = hasPrintOnTheGo || hasSmartSecurity;
  const printerLastSeenTime = device?.printerLastSeenTime;
  const hasStatus = isDisconnected || printerStateReasons.length > 0;
  const isMonitored =
    device?.securityAssessmentResult === STATUS.SECURITY.securityMonitored;
  const shouldShowRightArea = hasStatus || hasFeatures;
  const singleColumn = !shouldShowRightArea;

  const getPrinterStatus = useMemo(
    () => (
      <>
        <StatusHeader className="label">
          {t('homev2.printersTenantCard.expanded.printerDetails')}
        </StatusHeader>
        {isDisconnected && (
          <StatusContainer>
            <DeviceStatus status={STATUS.GENERAL.disconnected_from_internet} />
          </StatusContainer>
        )}
        {printerStateReasons?.map(
          (reason) =>
            reason && (
              <StatusContainer key={reason.key}>
                <DeviceReasonDetail
                  reason={reason}
                  withExplanation={false}
                />
              </StatusContainer>
            )
        )}
      </>
    ),
    [isDisconnected, printerStateReasons, t]
  );

  const getFeatures = useMemo(
    () => (
      <>
        <ServicesHeader className="label">
          {t('homev2.printersTenantCard.expanded.featuresAvailable')}
        </ServicesHeader>
        {hasPrintOnTheGo && (
          <ServiceItem
            data-testid="service-item-print-anywhere"
            icon={Images.benefitsCloudPrint}
            name={t(
              'homev2.printersTenantCard.expanded.features.printAnywhere'
            )}
            coachmarkText={t(
              'homev2.printersTenantCard.expanded.tooltip.printAnywhere'
            )}
          />
        )}
        {hasSmartSecurity && (
          <ServiceItem
            data-testid="service-item-secure-monitoring"
            icon={Images.benefitsShield}
            name={t(
              'homev2.printersTenantCard.expanded.features.securityMonitoring'
            )}
            sideText={
              isMonitored
                ? t('homev2.printersTenantCard.expanded.sideText.monitored')
                : t('homev2.printersTenantCard.expanded.sideText.notMonitored')
            }
            coachmarkText={
              isMonitored
                ? t(
                    'homev2.printersTenantCard.expanded.tooltip.secureMonitoring.monitored'
                  )
                : t(
                    'homev2.printersTenantCard.expanded.tooltip.secureMonitoring.notMonitored'
                  )
            }
          />
        )}
      </>
    ),
    [hasPrintOnTheGo, hasSmartSecurity, isMonitored, t]
  );

  return (
    <Container
      data-testid="dropdown-item"
      singleColumn={singleColumn}
    >
      <LeftArea singleColumn={singleColumn}>
        <CartridgeContainer>
          <SuppliesContent>
            <Supplies device={device} />
          </SuppliesContent>
        </CartridgeContainer>
      </LeftArea>
      {shouldShowRightArea && (
        <RightArea>
          {hasStatus && getPrinterStatus}
          {hasFeatures && hasStatus && <Separator />}
          {hasFeatures && getFeatures}
          {printerLastSeenTime && (
            <RightFooter className="label-small">
              {t('myPrinters.lastUpdatedTime', {
                timestamp: printerLastSeenTime,
                interpolation: { escapeValue: false }
              })}
            </RightFooter>
          )}
        </RightArea>
      )}
    </Container>
  );
};

DropdownItem.defaultProps = {
  hasPrintOnTheGo: false,
  hasSmartSecurity: false,
  securityAssessmentResult: null
};

DropdownItem.propTypes = {
  device: PropTypes.shape({
    identity: PropTypes.shape({
      supplyType: PropTypes.string
    }),
    status: PropTypes.shape({
      printerStateReasons: PropTypes.arrayOf(PropTypes.string),
      connectionState: PropTypes.string
    }),
    solutions: PropTypes.arrayOf(PropTypes.string),
    printerLastSeenTime: PropTypes.string
  }).isRequired,
  hasPrintOnTheGo: PropTypes.string,
  hasSmartSecurity: PropTypes.string,
  securityAssessmentResult: PropTypes.string
};

export default DropdownItem;
