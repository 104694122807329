import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import PrinterAccordion from 'src/shared/PrinterAccordion';
import { StyledLink, StyledLinkButton } from './styles';
import PATHS from 'src/constants/paths';
import { STATUS } from 'src/components/DeviceStatus/statusDictionary';
import { removePrinterModalTypes } from 'src/components/RemovePrinter/constants';
import { useRootContext, useI18n } from '@jarvis/react-portal-addons';
import {
  publishButtonClickedEvent,
  publishHyperLinkClickedEvent
} from 'src/utils/analytics';
import { getLegacyMyPrintersURL } from 'src/utils/utils';

const PrinterOptions = ({
  device,
  eligibility,
  subscriptionUpdatePlan,
  handleOpenTransferDeviceModal,
  handleOpenRemoveDeviceModal,
  programInfos,
  hasHpOnePrinters
}) => {
  const { stack, analytics, shell } = useRootContext();
  const { t } = useI18n();

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  const modelName = device?.identity?.makeAndModel?.name;

  const printerProperties =
    `connection=${device.status?.connectionState}` +
    `&security=${device.securityAssessmentResult}` +
    `&status=${device.printerHealth}`;

  const sendManagePrinterSettingsLinkEvent = useCallback(() => {
    publishHyperLinkClickedEvent(
      analytics,
      'ManagePrinterSettings',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const sendPrintAnywhereLinkEvent = useCallback(() => {
    publishHyperLinkClickedEvent(
      analytics,
      'PrintAnywhere',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const sendInviteUsersButtonEvent = useCallback(() => {
    publishHyperLinkClickedEvent(
      analytics,
      'InviteUsers',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const sendChangePlanLinkEvent = useCallback(() => {
    publishHyperLinkClickedEvent(
      analytics,
      subscriptionUpdatePlan === STATUS.UPDATE_PLAN.INSTANT_INK
        ? 'ChangePlan'
        : 'UpdatePlan',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [
    analytics,
    analyticsMetadata,
    modelName,
    printerProperties,
    subscriptionUpdatePlan
  ]);

  const sendTransferButtonEvent = useCallback(() => {
    publishButtonClickedEvent(
      analytics,
      'TransferOwnership',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const sendRemovePrinterButtonEvent = useCallback(() => {
    publishButtonClickedEvent(
      analytics,
      'RemovePrinter',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const sendManageSubscriptionEvent = useCallback(() => {
    publishHyperLinkClickedEvent(
      analytics,
      'ManageSubscription',
      modelName,
      'PrinterOptions',
      analyticsMetadata,
      printerProperties
    );
  }, [analytics, analyticsMetadata, modelName, printerProperties]);

  const content = useMemo(() => {
    if (device.isLegacy) {
      return (
        <StyledLink
          className="label"
          href={getLegacyMyPrintersURL(stack, shell)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => sendManagePrinterSettingsLinkEvent()}
        >
          {t('myPrinters.printerOptionsItems.managePrinterSettings')}
        </StyledLink>
      );
    }
    return (
      <>
        {programInfos?.hasPrintOnTheGo && (
          <StyledLink
            className="label"
            href={PATHS.MANAGE_USERS}
            onClick={() => sendInviteUsersButtonEvent()}
            data-testid="invite-users-pp"
          >
            {t('myPrinters.printerOptionsItems.inviteUsers')}
          </StyledLink>
        )}
        {programInfos?.hasPrintOnTheGo && device.hasPrintOnTheGo && (
          <StyledLink
            className="label"
            href={PATHS.PRINT_ANYWHERE}
            onClick={() => sendPrintAnywhereLinkEvent()}
            data-testid="potg-pp"
          >
            {t('myPrinters.printerOptionsItems.printAnywhere')}
          </StyledLink>
        )}
        {subscriptionUpdatePlan !== STATUS.UPDATE_PLAN.UNKNOWN && (
          <StyledLink
            className="label"
            href={
              subscriptionUpdatePlan === STATUS.UPDATE_PLAN.INSTANT_INK
                ? PATHS.CHANGE_PLAN
                : PATHS.UPDATE_PLAN
            }
            onClick={() => sendChangePlanLinkEvent()}
            data-testid="update-plan-pp"
          >
            {t('myPrinters.printerOptionsItems.changePlanII')}
          </StyledLink>
        )}
        {eligibility?.eligible && (
          <StyledLinkButton
            className="label"
            onClick={() => {
              handleOpenTransferDeviceModal(device, eligibility?.lastHPPlus);
              sendTransferButtonEvent();
            }}
          >
            {t('myPrinters.transferOwnership.label')}
          </StyledLinkButton>
        )}
        {!hasHpOnePrinters && (
          <StyledLinkButton
            className="label"
            onClick={() => {
              handleOpenRemoveDeviceModal(
                device,
                removePrinterModalTypes.PRINTER_MODAL_OPEN
              );
              sendRemovePrinterButtonEvent();
            }}
            data-testid="remove-printer-options-pp"
          >
            {t('myPrinters.printerOptionsItems.removePrinter')}
          </StyledLinkButton>
        )}
        {hasHpOnePrinters && (
          <StyledLink
            className="label"
            href={PATHS.HP_ONE_PRINTER.OVERVIEW}
            onClick={() => sendManageSubscriptionEvent()}
            data-testid="manage-subscription-pp"
          >
            {t('myPrinters.printerOptionsItems.manageSubscription')}
          </StyledLink>
        )}
      </>
    );
  }, [
    device,
    programInfos?.hasPrintOnTheGo,
    t,
    subscriptionUpdatePlan,
    eligibility?.eligible,
    eligibility?.lastHPPlus,
    hasHpOnePrinters,
    stack,
    sendManagePrinterSettingsLinkEvent,
    sendInviteUsersButtonEvent,
    sendPrintAnywhereLinkEvent,
    sendChangePlanLinkEvent,
    handleOpenTransferDeviceModal,
    sendTransferButtonEvent,
    handleOpenRemoveDeviceModal,
    sendRemovePrinterButtonEvent,
    sendManageSubscriptionEvent,
    shell
  ]);

  return (
    <PrinterAccordion
      id="printer-options-data"
      headerText={t('myPrinters.printerOptions')}
      content={content}
      dataTestId="printer-options-pp"
    />
  );
};

PrinterOptions.propTypes = {
  device: PropTypes.shape({
    identity: PropTypes.shape({
      platformIdentifier: PropTypes.string.isRequired,
      makeAndModel: PropTypes.shape({
        deviceUuid: PropTypes.string,
        number: PropTypes.string
      })
    })
  }),
  eligibility: PropTypes.shape({
    eligible: PropTypes.bool,
    lastHPPlus: PropTypes.bool
  }),
  subscriptionUpdatePlan: PropTypes.string,
  handleOpenTransferDeviceModal: PropTypes.func.isRequired,
  handleOpenRemoveDeviceModal: PropTypes.func.isRequired,
  hasPrintOnTheGo: PropTypes.bool
};

export default PrinterOptions;
