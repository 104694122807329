import React, { useEffect } from 'react';
import { Modal } from './Modal';
import { Button, ButtonGroup, Checkbox } from '@veneer/core';
import {
  CheckboxContainer,
  HeaderContainer,
  HeaderText,
  SelectContainer
} from './styles';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import {
  ModalScreenPath,
  publishButtonClickedEvent,
  publishModalDisplayedEvent
} from 'src/utils/analytics';

const OwnershipModal = ({
  openModal,
  onCloseModal,
  isTransferLoading,
  readyForTransfer,
  transferOwnership,
  organizations,
  onChangeSelectionBar,
  onClearSelectionBar,
  selectedOrganization,
  privacyChecked,
  checkboxChangeHandler,
  transferChecked,
  analyticsMetadata
}) => {
  const { t } = useI18n();
  const { analytics } = useRootContext();

  useEffect(() => {
    if (openModal) {
      publishModalDisplayedEvent(
        analytics,
        'TransferOwnershipModal',
        analyticsMetadata
      );
    }
  }, [analytics, analyticsMetadata, openModal]);

  return (
    <Modal
      id="regular-modal"
      data-testid="consumer-printers-transferModal"
      onClose={onCloseModal}
      show={openModal}
      footer={
        <ButtonGroup>
          <Button
            data-testid="consumer-printers-transferModal-cancel"
            appearance="secondary"
            onClick={() => {
              publishButtonClickedEvent(
                analytics,
                'Cancel',
                undefined,
                undefined,
                analyticsMetadata,
                undefined,
                ModalScreenPath,
                'TransferOwnershipModal'
              );
              onCloseModal();
            }}
            disabled={isTransferLoading}
          >
            {t('myPrinters.transferOwnershipModal.secondaryButton')}
          </Button>
          <Button
            data-testid="consumer-printers-transferModal-transfer"
            disabled={!readyForTransfer || isTransferLoading}
            onClick={() => {
              publishButtonClickedEvent(
                analytics,
                'Transfer',
                undefined,
                undefined,
                analyticsMetadata,
                undefined,
                ModalScreenPath,
                'TransferOwnershipModal'
              );
              transferOwnership();
            }}
          >
            {t('myPrinters.transferOwnershipModal.primaryButton')}
          </Button>
        </ButtonGroup>
      }
    >
      <HeaderContainer>
        <HeaderText
          className="title-small"
          data-testid="consumer-printers-transferModal-title"
        >
          {t('myPrinters.transferOwnershipModal.header')}
        </HeaderText>
      </HeaderContainer>
      <h4
        className="body"
        data-testid="consumer-printers-transferModal-subtitle"
      >
        {t('myPrinters.transferOwnershipModal.selectOrg.label')}
      </h4>
      <SelectContainer
        data-testid="consumer-printers-transferModal-orgSelector"
        options={organizations.data || []}
        id="select-usage"
        placement="bottom"
        helperTextVisibility="auto"
        onChange={onChangeSelectionBar}
        onClear={onClearSelectionBar}
        value={selectedOrganization}
        readOnly={isTransferLoading}
        required
      />
      <h4 className="body">
        {t('myPrinters.transferOwnershipModal.checkTerms.label')}
      </h4>
      <CheckboxContainer>
        <Checkbox
          data-testid="consumer-printers-transferModal-privacyInput"
          disabled={isTransferLoading}
          id="privacy"
          checked={privacyChecked}
          onClick={checkboxChangeHandler}
          label={
            <span
              className="body"
              data-testid="consumer-printers-transferModal-privacySubtitle"
            >
              {t('myPrinters.transferOwnershipModal.checkTerms.privacyTerms')}
            </span>
          }
        />
      </CheckboxContainer>
      <Checkbox
        data-testid="consumer-printers-transferModal-personalDataInput"
        disabled={isTransferLoading}
        id="transfer"
        checked={transferChecked}
        onClick={checkboxChangeHandler}
        label={
          <span
            className="body"
            data-testid="consumer-printers-transferModal-personalDataSubtitle"
          >
            {t('myPrinters.transferOwnershipModal.checkTerms.dataStoredTerms')}
          </span>
        }
      />
    </Modal>
  );
};

export default OwnershipModal;
