import { useCallback } from 'react';
import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useUserTenantApiCall({ authProvider, stack }) {
  const fetch = useCallback(async () => {
    const client = new UserTenantMgtSvcClient(stack, authProvider);
    const response = await client.getCurrentActiveUserWithTenant();
    return response.data;
  }, [authProvider, stack]);

  return useApiCall({ fetch });
}
