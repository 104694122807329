import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Link } from '@jarvis/react-portal-addons';

export const PrinterGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: auto;
`;

export const StyledLinkButton = styled.div`
  && {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    :hover {
      color: ${tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${tokens.colorHpBlue8};
      text-decoration: underline;
    }
  }
  cursor: pointer;
  font-size: ${tokens.fontSize2};
  @media (min-width: 1036px) {
    font-size: ${tokens.fontSize3};
  }
  padding: 0 0 0 20px;
  line-height: 28px;
  @media (min-width: ${tokens.lgMin}) {
    padding: 0 0 0 48px;
    line-height: 32px;
  }
`;

export const StyledLink = styled(Link)`
  font-size: ${tokens.fontSize2};
  @media (min-width: 1036px) {
    font-size: ${tokens.fontSize3};
  }
  display: block;
  padding: 0 0 0 20px;
  line-height: 28px;
  @media (min-width: ${tokens.lgMin}) {
    padding: 0 0 0 48px;
    line-height: 32px;
  }
`;

export const PrinterCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${tokens.xsMax}) {
    flex-direction: column;
  }

  @media (max-width: ${tokens.lgMin}) {
    padding: 0;
  }
`;

export const PrinterGroupSupplies = styled(PrinterGroup)`
  max-width: auto;
  justify-content: center;
  padding: 16px 8px;
  flex-direction: column;
  flex: 1 1 33%;

  @media (max-width: ${tokens.xsMax}) {
    border-top: 1px solid #e9e5e5;
  }

  @media (min-width: ${tokens.smMin}) {
    border-left: 1px solid #e9e5e5;
    max-width: 33%;
  }

  @media (min-width: ${tokens.lgMin}) {
    padding: 32px 32px 32px 16px;
  }
`;

export const PrinterMeta = styled(PrinterGroup)`
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 8px 16px 16px;

  @media (min-width: ${tokens.smMin}) {
    ${(props) => (props.withSupplies ? 'max-width: 66%;' : '')};
  }

  @media (min-width: ${tokens.lgMin}) {
    padding: 32px 16px 32px 32px;
  }

  ${StyledLink} {
    width: 58px;
  }

  @media (min-width: ${tokens.lgMin}) {
    ${StyledLink} {
      width: 100px;
    }
  }
`;

export const PrinterGroupImage = styled(PrinterGroup)`
  display: block;
  text-align: center;
  width: 58px;
  img {
    position: relative;
    width: 58px;
    height: auto;
  }

  @media (min-width: ${tokens.smMax}) {
    width: 100px;
    img {
      width: 100px;
    }
  }
`;

export const PrinterCardFooter = styled(PrinterGroup)`
  && {
    background-color: #f9f9f9;
    border-radius: 0 0 16px 16px;
    font-size: ${tokens.fontSize1};
    min-height: 24px;
    flex-direction: column;
    padding: 0 12px;
    text-align: center;

    span {
      font-weight: bold;
    }

    @media (min-width: 1036px) {
      font-size: ${tokens.fontSize2};
      min-height: 32px;
    }
  }
`;

export const PrinterGroupText = styled(PrinterGroup)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;

  img {
    margin-bottom: 8px;
  }

  @media (min-width: ${tokens.lgMin}) {
    margin-left: 32px;
  }
`;

export const SubscribeLink = styled(Link)`
  && {
    text-align: ${(props) => (props.leftAlign ? 'left' : 'center')};
    font-size: ${tokens.fontSize1};
    @media (min-width: 1036px) {
      font-size: ${tokens.fontSize2};
    }
  }
`;

export const ProgressBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
  height: 100px;
`;

export const DeviceStatusContainer = styled.div`
  margin-top: 8px;
  margin-left: 0;

  @media (min-width: 1036px) {
    margin-left: 14px;
  }
`;
