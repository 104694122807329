import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Modal, ButtonGroup } from '@veneer/core';

export const RemovePrinter = styled.div``;

export const ModalLine = styled.div`
  :not(:last-child) {
    padding-bottom: 15px;
  }
  color: rgb(33, 33, 33);
  text-align: center;
  @media (max-width: ${tokens.smMax}) {
    text-align: center;
  }
`;
export const ModalContainer = styled(Modal)``;

export const ModalButtonGroup = styled(ButtonGroup)`
  width: 100%;
  justify-content: center;
`;

export const RemovePrinterProgress = styled.div`
  padding-bottom: 15px;
  display: flex;
  width: 100%;
  justify-content: center;
`;
