import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SupplyDelivery,
  ValidSupplyTypes,
  DeliveryMapForType
} from './constants';
import { SuppliesContainer, SuppliesStatus } from './styles';
import Supply from './supply';
import {
  useI18n,
  useRootContext,
  MFELoader
} from '@jarvis/react-portal-addons';

const Supplies = ({ device }) => {
  const { t } = useI18n();
  const [supplies, setSupplies] = useState([]);
  const [supplyStringId, setSupplyStringId] = useState(
    'home.myPrintersCard.label'
  );

  const { authProvider, featureFlags, stack } = useRootContext();
  const shell = window.Shell;

  const useSmbSupplies = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'useSmbSupplies',
      defaultValue: false
    }
  );
  const deviceSupplyUseLevelOnError = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'deviceSupplyUseLevelOnError',
      defaultValue: false
    }
  );

  useEffect(() => {
    if (useSmbSupplies) {
      return;
    }

    const supplyDelivery =
      device.identity?.supplyDelivery || SupplyDelivery.cartridge;

    setSupplies(
      device.supplies?.consumables
        ?.filter((supply) => {
          if (!ValidSupplyTypes.includes(supply.supplyType)) return false;
          return DeliveryMapForType[supplyDelivery].includes(supply.supplyType);
        })
        ?.map((supply) => ({
          slot: supply.slotOrder || 0,
          name: supply.colorCode, // CMY, C, M, Y, K
          isRefill: supply.isRefilled && !supply.isVaReman,
          levelState: supply.levelState, // depleted, fulfillment, low, ok, unknown, veryLow, veryVeryLow
          supplyState: supply.state, // error, inform, ok, warning
          level: supply.percentLifeDisplay
        }))
        ?.sort((a, b) => a.slot - b.slot) || []
    );

    if (supplyDelivery === SupplyDelivery.tank) {
      setSupplyStringId('home.myPrintersCard.labelTank');
    }
  }, [device.identity, device.supplies, useSmbSupplies]);

  if (useSmbSupplies) {
    return (
      <>
        <MFELoader
          authProvider={authProvider}
          featureFlags={{ deviceSupplyUseLevelOnError }}
          shell={shell}
          stack={stack}
          component="@jarvis/react-smb-printer-supplies"
          deviceId={device.deviceId}
          modelNumber={device.identity?.makeAndModel?.number}
          platformIdentifier={device.identity?.platformIdentifier}
        />
      </>
    );
  }

  return (
    <>
      <SuppliesContainer>
        {supplies?.map((supply) => (
          <Supply
            key={`supply_${supply.name}`}
            supply={supply}
          />
        ))}
      </SuppliesContainer>
      {supplies?.length > 0 && (
        <SuppliesStatus className="label">{t(supplyStringId)}</SuppliesStatus>
      )}
    </>
  );
};

Supplies.propTypes = {
  device: PropTypes.shape({
    identity: PropTypes.shape({
      supplyDelivery: PropTypes.string
    }),
    supplies: PropTypes.shape({
      consumables: PropTypes.arrayOf(
        PropTypes.shape({
          colorCode: PropTypes.string,
          isRefilled: PropTypes.bool,
          isVaReman: PropTypes.bool,
          levelState: PropTypes.string,
          percentLifeDisplay: PropTypes.number,
          slotOrder: PropTypes.number,
          state: PropTypes.string,
          supplyType: PropTypes.string
        })
      )
    })
  })
};

export default Supplies;
