import styled from 'styled-components';
import { Card } from '@veneer/core';
import Tokens from '@veneer/tokens';

export const PrintersContainer = styled.div``;

export const PrinterBox = styled(Card)`
  border-color: #ffffff;
  width: 640px;
  margin-bottom: ${Tokens.space6};
  :last-child {
    margin-bottom: ${Tokens.space8};
  }
  @media (max-width: ${Tokens.lgMax}) {
    width: auto;
  }
`;

export const PrinterAccount = styled.div`
  margin-bottom: ${Tokens.space5};
  text-align: center;
`;

export const NoPrintersSection = styled.div`
  padding: ${Tokens.space8};
`;

export const NoPrintersIcon = styled.img`
  display: block;
  margin: auto auto 32px;
`;
