const CONFIG = {
  ACTIVITY: 'PrintersMgmt-v01',
  EVENT_DETAIL_VERSION: '1.4.0',
  SCREEN_NAME: 'Printers',
  SCREEN_PATH: '/ReactHpxDevices/',
  EVENT_DETAIL_TYPE:
    'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
  HOME_SCREEN_PATH: '/ReactHpxDevices/',
  EVENT_CATEGORY: 'simpleUi'
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  MODAL_DISPLAYED: 'ModalWindowDisplayed',
  HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  BUTTON_CLICKED: 'ControlButtonClicked'
};

const BASE_EVENT = {
  dateTime: new Date().toISOString(),
  eventDetailType: CONFIG.EVENT_DETAIL_TYPE,
  eventCategory: CONFIG.EVENT_CATEGORY,
  eventDetail: {
    activity: CONFIG.ACTIVITY,
    version: CONFIG.EVENT_DETAIL_VERSION
  },
  version: CONFIG.EVENT_DETAIL_VERSION
};

export const ModalScreenPath = `${CONFIG.SCREEN_PATH}Printers/`;

export const publishModalDisplayedEvent = (analytics, screenName, metadata) => {
  analytics.publishCdmEvents(
    [
      {
        ...BASE_EVENT,
        eventDetail: {
          ...BASE_EVENT.eventDetail,
          action: ACTIONS.MODAL_DISPLAYED,
          screenPath: ModalScreenPath,
          screenName: screenName
        }
      }
    ],
    {
      metadata: {
        associatedDeviceUuid: metadata.associatedDeviceUuid,
        associatedDeviceProductNumber: metadata.associatedDeviceProductNumber
      }
    }
  );
};

export const publishButtonClickedEvent = (
  analytics,
  controlName,
  controlDetail,
  screenMode,
  metadata,
  actionAuxParams,
  screenPath = CONFIG.SCREEN_PATH,
  screenName = CONFIG.SCREEN_NAME
) => {
  analytics.publishCdmEvents(
    [
      {
        ...BASE_EVENT,
        eventDetail: {
          ...BASE_EVENT.eventDetail,
          action: ACTIONS.BUTTON_CLICKED,
          screenPath,
          screenName,
          controlName,
          controlDetail,
          screenMode,
          actionAuxParams
        }
      }
    ],
    metadata && {
      metadata: {
        associatedDeviceUuid: metadata.associatedDeviceUuid,
        associatedDeviceProductNumber: metadata.associatedDeviceProductNumber
      }
    }
  );
};

export const publishHyperLinkClickedEvent = (
  analytics,
  controlName,
  controlDetail,
  screenMode,
  metadata,
  actionAuxParams
) => {
  analytics.publishCdmEvents(
    [
      {
        ...BASE_EVENT,
        eventDetail: {
          ...BASE_EVENT.eventDetail,
          action: ACTIONS.HYPERLINK_CLICKED,
          screenPath: CONFIG.SCREEN_PATH,
          screenName: CONFIG.SCREEN_NAME,
          screenMode,
          controlName,
          controlDetail,
          actionAuxParams
        }
      }
    ],
    metadata && {
      metadata: {
        associatedDeviceUuid: metadata.associatedDeviceUuid,
        associatedDeviceProductNumber: metadata.associatedDeviceProductNumber
      }
    }
  );
};

export const publishScreenDisplayedEvent = (analytics, actionAuxParams) => {
  analytics.publishCdmEvents([
    {
      ...BASE_EVENT,
      eventDetail: {
        ...BASE_EVENT.eventDetail,
        action: ACTIONS.SCREEN_DISPLAYED,
        screenPath: CONFIG.HOME_SCREEN_PATH,
        screenName: CONFIG.SCREEN_NAME,
        actionAuxParams
      }
    }
  ]);
};
