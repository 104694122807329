import React, { useEffect, useMemo, useState } from 'react';
import TransferErrorModal from './TransferErrorModal';
import LastPrinterModal from './LastPrinterModal';
import TransferInProcessModal from './TransferInProcessModal';
import TransferSuccessModal from './TransferSuccessModal';
import OwnershipModal from './OwnershipModal';
import useDeviceTransferApiCall from 'src/hooks/useDeviceTransferApiCall';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import useRelationshipsApiCall from 'src/hooks/useRelationshipsApiCall';

const TransferOwnershipModal = ({
  handleCloseModal,
  openModal,
  device,
  userInfo,
  accountInfo,
  isLastHPPlusPrinter
}) => {
  const [lastPrinterConfirmed, setLastPrinterConfirmed] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [transferChecked, setTransferChecked] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [readyForTransfer, setReadyForTransfer] = useState(false);
  const [transferError, setTransferError] = useState(false);
  const [transferSuccess, setTransferSuccess] = useState(false);
  const [selectedOrganizationName, setSelectedOrganizationName] = useState('');
  const { t } = useI18n();

  const { authProvider, stack } = useRootContext();
  const deviceTransfer = useDeviceTransferApiCall({ authProvider, stack });
  const organizations = useRelationshipsApiCall({ authProvider, stack });

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  useEffect(() => {
    if (
      !privacyChecked ||
      !transferChecked ||
      !selectedOrganization.length ||
      deviceTransfer.isFetching
    ) {
      setReadyForTransfer(false);
    } else {
      setReadyForTransfer(true);
    }
  }, [
    privacyChecked,
    transferChecked,
    selectedOrganization,
    deviceTransfer.isFetching
  ]);

  useEffect(() => {
    if (deviceTransfer.success) {
      setTransferSuccess(true);
      setTransferError(false);
    }
    if (deviceTransfer.error) {
      setTransferSuccess(false);
      setTransferError(true);
    }
  }, [deviceTransfer.success, deviceTransfer.error]);

  useEffect(() => {
    if (!openModal || !accountInfo) return;
    if (!organizations.isFetching && !organizations.data) {
      organizations.makeApiCall({
        parentOrg: accountInfo.resourceId,
        parentOrgName: t(
          'myPrinters.transferOwnershipModal.selectOrg.fixedOption'
        )
      });
    }
  }, [accountInfo, openModal, organizations, t]);

  const checkboxChangeHandler = (event) => {
    if (!deviceTransfer.isFetching) {
      const id = event.target.id;
      switch (id) {
        case 'privacy':
          setPrivacyChecked(!privacyChecked);
          break;
        case 'transfer':
          setTransferChecked(!transferChecked);
          break;
        default:
          return;
      }
    }
  };

  const onChangeSelectionBar = (selectedOption) => {
    if (!deviceTransfer.isFetching) {
      setSelectedOrganization([selectedOption?.value]);
    }
  };

  const onClearSelectionBar = () => {
    if (!deviceTransfer.isFetching) {
      setSelectedOrganization([]);
    }
  };

  const onCloseModal = () => {
    if (!deviceTransfer.isFetching) {
      handleCloseModal();
      setPrivacyChecked(false);
      setTransferChecked(false);
      setSelectedOrganization([]);
      setTransferError(false);
      setTransferSuccess(false);
      setLastPrinterConfirmed(false);
    }
  };

  const transferOwnership = async () => {
    if (!readyForTransfer) return;
    if (!userInfo) return;

    const selectedOrg = organizations.data.find(
      (org) => org.value === selectedOrganization[0]
    );

    setSelectedOrganizationName(selectedOrg.label);

    deviceTransfer.makeApiCall({
      deviceId: device.deviceId,
      fromAccountId: device.ownership.accountId,
      toAccountId: selectedOrg.otherData.nodeId,
      userId: userInfo.user.resourceId
    });
  };

  const handleConfirmLastPrinter = () => {
    setLastPrinterConfirmed(true);
  };

  if (isLastHPPlusPrinter && !lastPrinterConfirmed) {
    return (
      <LastPrinterModal
        openModal={openModal}
        handleCloseModal={onCloseModal}
        onContinue={handleConfirmLastPrinter}
      />
    );
  }

  if (transferError) {
    return (
      <TransferErrorModal
        onCloseModal={onCloseModal}
        openModal={openModal}
      />
    );
  }
  if (deviceTransfer.isFetching) {
    return (
      <TransferInProcessModal
        openModal={openModal}
        onCloseModal={onCloseModal}
        selectedOrganizationName={selectedOrganizationName}
      />
    );
  }
  if (transferSuccess) {
    return (
      <TransferSuccessModal
        openModal={openModal}
        onCloseModal={onCloseModal}
        selectedOrganizationName={selectedOrganizationName}
      />
    );
  }
  return (
    <OwnershipModal
      openModal={openModal}
      onCloseModal={onCloseModal}
      isTransferLoading={deviceTransfer.isFetching}
      readyForTransfer={readyForTransfer}
      transferOwnership={transferOwnership}
      organizations={organizations}
      onChangeSelectionBar={onChangeSelectionBar}
      onClearSelectionBar={onClearSelectionBar}
      selectedOrganization={selectedOrganization}
      privacyChecked={privacyChecked}
      checkboxChangeHandler={checkboxChangeHandler}
      transferChecked={transferChecked}
      analyticsMetadata={analyticsMetadata}
    />
  );
};

export default TransferOwnershipModal;
