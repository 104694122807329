import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const SuppliesStatus = styled.div`
  && {
    text-align: center;
    font-size: 10px;
    line-height: ${tokens.lineHeight2};
    @media (min-width: 1036px) {
      font-size: 15px;
    }
  }
`;

export const SuppliesContainer = styled.div`
  position: relative;
  white-space: nowrap;
`;

const getLevelHeight = (level, max) =>
  Math.min(max, Math.max(0, (max * level) / 100));

export const SupplyContainer = styled.div`
  box-sizing: border-box;
  ::after,
  ::before,
  div,
  div::after,
  div::before {
    box-sizing: border-box;
  }

  // cartridge container
  line-height: 0;
  display: inline-block;
  max-height: 68px;
  @media (min-width: 600px) {
    max-height: 110px;
  }

  & > div.container {
    // cartridge
    display: inline-block;
    position: relative;
    overflow: hidden;

    margin-left: 1px;
    margin-right: 1px;
    height: 42px;
    width: 9px;
    border-radius: 6px;

    @media (min-width: 600px) {
      margin-left: 3px;
      margin-right: 3px;
      height: 82px;
      width: 12px;
    }

    &.border-none {
      border: 1px solid transparent;
    }
    &.border-error {
      border: 2px dotted #d0021b;
      @media (min-width: 600px) {
        border-style: dashed;
      }
    }
    &.border-warning {
      border: 2px solid #e8701a;
    }

    & > div.bar {
      // cartridge bar
      display: block;
      position: absolute;
      background-color: #f3f3f3;
      width: 7px;
      height: 40px;
      @media (min-width: 600px) {
        width: 10px;
        height: 80px;
      }

      top: 0;
      left: 0;

      &.cyan div.level {
        // cartridge level
        background-color: #03a4f0;
      }
      &.magenta div.level {
        // cartridge level
        background-color: #f7448b;
      }
      &.yellow div.level {
        // cartridge level
        background-color: #ffd906;
      }
      &.black .level {
        // cartridge level
        background-color: #000000;
      }

      & > div.level {
        // cartridge level
        width: 7px;
        height: ${({ level }) => getLevelHeight(level, 49)}px;
        @media (min-width: 600px) {
          width: 10px;
          height: ${({ level }) => getLevelHeight(level, 95)}px;
        }
        position: absolute;
        bottom: 0;

        & > div {
          // cartridge level grad
          height: 3px;
          width: 7px;
          @media (min-width: 600px) {
            height: 5px;
            width: 10px;
          }
          background-color: #ffffff;
          &.grad25 {
            opacity: 0.75;
          }
          &.grad50 {
            opacity: 0.5;
          }
          &.grad75 {
            opacity: 0.25;
          }
        }
      }
    }

    &.tricolor {
      width: 25px;
      @media (min-width: 600px) {
        width: 34px;
      }

      & > div.bar {
        &.magenta {
          left: 8px;
          @media (min-width: 600px) {
            left: 11px;
          }
        }
        &.yellow {
          left: 16px;
          @media (min-width: 600px) {
            left: 22px;
          }
        }
      }
    }
  }
`;

export const SupplyDot = styled.div`
  box-sizing: border-box;
  ::after,
  ::before,
  div,
  div::after,
  div::before {
    box-sizing: border-box;
  }

  & > div.dot {
    // cartridge info
    position: relative;
    width: 9px;
    height: 18px;
    margin: 1px;
    @media (min-width: 600px) {
      width: 12px;
      height: 18px;
      margin: 3px;
    }

    & > div {
      // cartridge dot
      width: 5px;
      height: 5px;
      border-radius: 6px;
      position: absolute;
      top: 6.5px;
      left: 2px;

      @media (min-width: 600px) {
        width: 8px;
        height: 8px;
        top: 5px;
        left: 2px;
      }

      &.cyan {
        background-color: #03a4f0;
      }
      &.magenta {
        background-color: #f7448b;
      }
      &.yellow {
        background-color: #ffd906;
      }
      &.black {
        background-color: #000000;
      }
    }

    &.tricolor {
      width: 25px;
      @media (min-width: 600px) {
        width: 34px;
      }
      & > div {
        &.cyan {
          top: 10.25px;
          left: 6.25px;
          @media (min-width: 600px) {
            top: 9.8px;
            left: 7px;
          }
        }
        &.magenta {
          top: 10.25px;
          left: 13.75px;
          @media (min-width: 600px) {
            top: 9.8px;
            left: 19px;
          }
        }
        &.yellow {
          top: 3.5px;
          left: 10px;
          @media (min-width: 600px) {
            top: 0.2px;
            left: 13px;
          }
        }
      }
    }
  }
`;
