import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import tokens from '@veneer/tokens';

export const PrinterItem = styled.section`
  padding: ${isMobile ? '16px 16px' : '20px 22px'};
  align-items: center;
  display: grid;
  grid-template-columns: 80px auto;

  @media (max-width: ${tokens.smMin}) {
    padding: 20px 0px;
  }
`;

export const PrinterImage = styled.img`
  position: relative;
  width: 80px;
  height: auto;
`;

export const PrinterName = styled.div`
  grid-row: 1;
  margin-bottom: 6px;
  font-size: ${tokens.fontSize6};
  @media (max-width: ${tokens.smMin}) {
    font-size: ${tokens.fontSize3};
  }
`;

export const PrinterStatus = styled.div`
  grid-row: 2;
  display: flex;
`;

export const PrinterInfo = styled.div`
  padding-left: 26px;
  justify-items: start;
  flex-direction: column;
  color: #212121;
  display: grid;
  grid-template-rows: auto;

  @media (max-width: ${tokens.smMin}) {
    padding-left: 12px;
  }
`;
