import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Accordion as VeneerAccordion } from '@veneer/core';

export const Accordion = styled(VeneerAccordion)`
  && {
    border-radius: ${tokens.borderRadius1};
    border-top: 1px solid #e9e5e5;
  }
`;

export const AccordionHeaderText = styled.div`
  && {
    font-size: ${tokens.fontSize2};
    @media (min-width: 1036px) {
      font-size: ${tokens.fontSize4};
    }
    margin-left: 4px;
    @media (min-width: ${tokens.lgMin}) {
      margin-left: 20px;
    }
  }
`;
