// TODO: idea: paths should come from manifest, having a /gateway in front when inside gateway routes
const PATHS = {
  CHANGE_PLAN: '/print_plans/my_account',
  INSTANT_INK: '/print_plans',
  MANAGE_USERS: '/account-details/manage-users',
  MY_PRINTERS: '/my-printers',
  PRINT_ANYWHERE: '/services/print-on-the-go',
  HP_PLUS_SECOND_OFFER: '/my-printers/activate-hp-plus',
  UPDATE_PLAN: '/hp-all-in-print-plan/update-plan',
  HP_ONE_PRINTER: {
    OVERVIEW: '/hp-all-in-print-plan/overview'
  }
};

export default PATHS;
