export const Entitlement = {
  AdvanceScan: 'ws-hp.com/scan',
  AdvanceShortcuts: 'ws-hp.com/shortcuts',
  SmartDashboard: 'ws-hp.com/smartdashboard',
  SmartSecurity: 'ws-hp.com/smartsecurity',
  PrintOnTheGo: 'ws-hp.com/potg',
  SmartAdvance: 'ws-hp.com/smart-advance',
  Warranty: 'ws-hp.com/warranty',
  PositivePrinting: 'ws-hp.com/positiveprinting',
  PrinterAsAService: 'ws-hp.com/paas',
  PrintPlans: 'ws-hp.com/printplans',
  InstantInk: 'ws-hp.com/instantink',
  InkDelivery: 'ws-hp.com/ink-delivery',
  HpOnePrinters: 'ws-hp.com/paas'
};

export const EntitlementState = {
  Enabled: 'ENABLED',
  Published: 'Published',
  Disabled: 'DISABLED',
  Pending: 'PENDING'
};

export const EntitlementStateSet = {
  Enabled: [EntitlementState.Enabled, EntitlementState.Published],
  Active: [EntitlementState.Enabled, EntitlementState.Pending],
  Benefit: [EntitlementState.Enabled, EntitlementState.Disabled],
  ServiceActive: [
    EntitlementState.Enabled,
    EntitlementState.Pending,
    EntitlementState.Disabled
  ],
  Acceptable: [
    EntitlementState.Enabled,
    EntitlementState.Pending,
    EntitlementState.Disabled,
    EntitlementState.Published
  ]
};

export const EntitlementLevel = {
  Device: 'DEVICE',
  Account: 'ACCOUNT'
};

export const EntitlementCategory = {
  Solution: 'SOLUTION',
  Manage: 'MANAGE',
  Benefit: 'BENEFIT',
  Paid: 'PAID'
};

export const getEntitlement = ({
  programInfos,
  entitlement,
  states,
  category,
  level
}) => {
  let map =
    programInfos?.programInfoMap?.YETI || programInfos?.programInfoMap?.UCDE;
  if (programInfos?.entitlementList) {
    map = programInfos;
  }

  const entitlements = (map?.entitlementList || []).concat(
    map?.subscriptionOfferingList || []
  );

  return entitlements.find(
    (item) =>
      item.serviceId === entitlement &&
      (!states || states.includes(item.state)) &&
      (!category || !item?.type || item.type === category) &&
      (!level || item.level === level)
  );
};
