export const removePrinterModalTypes = {
  PRINTER_MODAL_REMOVING: 'removing',
  PRINTER_MODAL_FAILED: 'failed',
  PRINTER_MODAL_OPEN: 'open',
  PRINTER_MODAL_REMOVED: 'removed'
};

export const statusDeleteDeviceErrorMessage = {
  HAS_SUBSCRIPTION: 'hasSubscription',
  HAS_WFH: 'hasWfh',
  IS_LAST_HPPLUS: 'isLastHpPlus',
  IS_SHARED: 'isShared',
  NOT_MAPPED: 'notMapped'
};

export const statusDeleteDeviceErrorCode = {
  EB000U0404: statusDeleteDeviceErrorMessage.HAS_SUBSCRIPTION,
  EB000U0406: statusDeleteDeviceErrorMessage.HAS_WFH,
  EB000U0407: statusDeleteDeviceErrorMessage.IS_LAST_HPPLUS,
  EB000U0408: statusDeleteDeviceErrorMessage.IS_SHARED
};
