import { useCallback } from 'react';
import useApiCall from './useApiCall';
import getJobsFaxCounters from '../services/getJobsFaxCounters';
import getJobsPrintCounters from '../services/getJobsPrintCounters';
import getJobsScanCounters from '../services/getJobsScanCounters';
import getJobsPrintAreaCounters from '../services/getJobsPrintAreaCounters';

function useJobTelemetryApiCall({
  authProvider,
  stack,
  params,
  init = {
    printPages: true,
    printArea: true,
    scan: true,
    fax: true,
  },
}) {
  const fetchFaxCounter = useCallback(
    ({ deviceId, startDate, endDate }) => getJobsFaxCounters(
      {
        stack, authProvider, startDate, endDate, deviceId,
      },
    ),
    [authProvider, stack],
  );
  const faxCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchFaxCounter,
    params,
    init: init.fax,
  });

  const fetchPrintCounter = useCallback(
    ({ deviceId, startDate, endDate }) => getJobsPrintCounters({
      stack, authProvider, startDate, endDate, deviceId,
    }),
    [authProvider, stack],
  );
  const printCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchPrintCounter,
    params,
    init: init.printPages,
  });

  const fetchScanCounter = useCallback(
    ({ deviceId, startDate, endDate }) => getJobsScanCounters(
      {
        stack, authProvider, startDate, endDate, deviceId,
      },
    ),
    [authProvider, stack],
  );
  const scanCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchScanCounter,
    params,
    init: init.scan,
  });

  const fetchPrintAreaCounter = useCallback(
    ({ deviceId, startDate, endDate }) => getJobsPrintAreaCounters({
      stack, authProvider, startDate, endDate, deviceId,
    }),
    [authProvider, stack],
  );

  const printAreaCounterCall = useApiCall({
    initialData: null,
    apiCall: fetchPrintAreaCounter,
    params,
    init: init.printArea,
  });

  const data = {
    print: printCounterCall,
    printArea: printAreaCounterCall,
    scan: scanCounterCall,
    fax: faxCounterCall,
  };

  const pending = printCounterCall.pending
               || printAreaCounterCall.pending
               || scanCounterCall.pending
               || faxCounterCall.pending;

  const success = printCounterCall.success
               || printAreaCounterCall.success
               || scanCounterCall.success
               || faxCounterCall.success;

  const error = printCounterCall.error
             || printAreaCounterCall.error
             || scanCounterCall.error
             || faxCounterCall.error;

  const allRequestsFailed = printCounterCall.error
             && printAreaCounterCall.error
             && scanCounterCall.error
             && faxCounterCall.error;

  const makeApiCall = useCallback(args => {
    if (init.printPages) {
      printCounterCall.makeApiCall(args);
    }

    if (init.printArea) {
      printAreaCounterCall.makeApiCall(args);
    }

    if (init.scan) {
      scanCounterCall.makeApiCall(args);
    }

    if (init.fax) {
      faxCounterCall.makeApiCall(args);
    }
  }, [
    printCounterCall,
    printAreaCounterCall,
    scanCounterCall,
    faxCounterCall,
    init.printPages,
    init.printArea,
    init.scan,
    init.fax,
  ]);

  return {
    allRequestsFailed,
    data,
    pending,
    error,
    success,
    makeApiCall,
  };
}

export default useJobTelemetryApiCall;
