import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GenericChart from '../GenericChart';
import { mediaQueries } from '../../../helpers/mixins/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  buildTooltipTitleMapping,
  tooltipFormatter,
} from '../utils';

const SERIES_IDS = {
  SENT: 0,
  RECEIVED: 1,
  AVERAGE_SENT: 2,
  AVERAGE_RECEIVED: 3,
};

const COLORS_DEFAULT = ['#00A55C', '#272968', '#672F4F', '#FFAC4D'];
const COLORS_HIGHLIGHTED = ['#00D072', '#2D2F86'];

const FaxChart = ({
  chartData,
  error,
  loading,
  onRetry,
  pointStartAverage,
  selectedYear,
  i18n,
  noData,
}) => {
  const [sharedTooltip, setSharedTooltip] = useState(true);
  const isMobile = useMediaQuery(mediaQueries.xs);

  const isDeviceSupportTouch = ('ontouchstart' in window)
                                || (navigator.maxTouchPoints > 0)
                                || (navigator.msMaxTouchPoints > 0);

  const {
    sent,
    received,
    maxAxisY,
  } = chartData;
  const isMultipleFaxUsage = sent && received;

  const widthLegend = useMemo(() => {
    if (!isMultipleFaxUsage) {
      return null;
    }

    const legendText = sent ? i18n.legend.averageSent : i18n.legend.averageReceived;
    return legendText.length < 30 ? '400px' : '450px';
  }, [
    i18n.legend.averageSent,
    i18n.legend.averageReceived,
    isMultipleFaxUsage,
    sent,
  ]);

  const legendTitles = useMemo(() => ({
    [SERIES_IDS.SENT]: i18n.legend.sent,
    [SERIES_IDS.RECEIVED]: i18n.legend.received,
    [SERIES_IDS.AVERAGE_SENT]: i18n.legend.averageSent,
    [SERIES_IDS.AVERAGE_RECEIVED]: i18n.legend.averageReceived,
  }), [
    i18n.legend.averageReceived,
    i18n.legend.averageSent,
    i18n.legend.received,
    i18n.legend.sent,
  ]);

  const tooltipXAxisMapping = useMemo(() => buildTooltipTitleMapping(
    i18n.xAxis.categories, i18n.tooltip.categories,
  ),
  [i18n.xAxis.categories, i18n.tooltip.categories]);

  const tooltip = useMemo(() => ({
    shared: sharedTooltip,
    followPointer: sharedTooltip,
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      const chart = this;
      return tooltipFormatter({
        chart,
        i18n,
        legendTitles,
        defaultTitle: tooltipXAxisMapping[chart.x],
        isDeviceTouch: isDeviceSupportTouch,
        showAverageUnit: false,
        showUnit: false,
        showTotal: false,
        reverseOrder: false,
      });
    },
  }), [
    i18n,
    isDeviceSupportTouch,
    legendTitles,
    sharedTooltip,
    tooltipXAxisMapping,
  ]);

  const numCategories = i18n.xAxis.categories.length;
  const isZeroLineOnBottom = useMemo(() => {
    if (isMultipleFaxUsage && !sent?.average && !received?.average) {
      return true;
    }

    if (!isMultipleFaxUsage) {
      if (sent && !sent.average) {
        return true;
      }

      if (received && !received.average) {
        return true;
      }
    }

    return false;
  }, [isMultipleFaxUsage, sent, received]);

  const options = useMemo(() => ({
    plotOptions: {
      column: {
        pointWidth: isMultipleFaxUsage ? 10 : 16,
        borderRadiusTopLeft: (sent?.average || received?.average) ? 2 : 0,
        borderRadiusTopRight: (sent?.average || received?.average) ? 2 : 0,
      },
      series: {
        marker: {
          radius: 2,
          fillColor: '#FFFFFF',
          lineWidth: 1,
          lineColor: null, // inherit from series
        },
      },
    },
    legend: {
      align: 'center',
      width: isMobile ? null : widthLegend,
      itemMarginTop: 8,
      margin: 4,
      itemDistance: isMultipleFaxUsage ? 0 : null,
    },
    yAxis: {
      ...(isZeroLineOnBottom ? {
        minRange: 0.1,
        tickInterval: 1,
      } : {}),
      min: 0,
      max: isMobile ? maxAxisY : null,
      title: {
        text: i18n.yAxis,
      },
    },
    xAxis: {
      min: 0,
      categories: i18n.xAxis.categories,
      max: isMobile ? 4 : numCategories,
      scrollbar: {
        enabled: isMobile,
      },
    },
    series: [
      ...(sent ? [{
        id: SERIES_IDS.AVERAGE_SENT,
        type: 'line',
        name: i18n.legend.averageSent,
        data: sent.counters.slice(pointStartAverage).map(val => (val === null ? val : sent.average)),
        color: COLORS_DEFAULT[2],
        zIndex: 1,
        legendIndex: 1,
        stickyTracking: false,
        isTooltipAverage: true,
        pointStart: pointStartAverage,
        selectedYear,
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        pointPlacement: isMultipleFaxUsage ? -0.15 : undefined,
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      }] : []),
      ...(received ? [{
        id: SERIES_IDS.AVERAGE_RECEIVED,
        type: 'line',
        name: i18n.legend.averageReceived,
        data: received.counters.slice(pointStartAverage).map(val => (val === null ? val : received.average)),
        color: COLORS_DEFAULT[3],
        zIndex: 1,
        legendIndex: 3,
        stickyTracking: false,
        isTooltipAverage: true,
        pointStart: pointStartAverage,
        selectedYear,
        pointPlacement: isMultipleFaxUsage ? 0.15 : undefined,
        marker: {
          enabled: false,
          symbol: 'circle',
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        events: {
          mouseOver() {
            setSharedTooltip(false);
          },
          mouseOut() {
            setSharedTooltip(true);
          },
        },
      }] : []),
      ...(sent ? [{
        id: SERIES_IDS.SENT,
        name: i18n.legend.sent,
        legendIndex: 0,
        data: sent.counters,
        type: 'column',
        color: COLORS_DEFAULT[0],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: COLORS_HIGHLIGHTED[0],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: COLORS_DEFAULT[0],
            });
          },
        },
      }] : []),
      ...(received ? [{
        id: SERIES_IDS.RECEIVED,
        name: i18n.legend.received,
        legendIndex: 2,
        data: received.counters,
        type: 'column',
        color: COLORS_DEFAULT[1],
        events: {
          mouseOver() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: COLORS_HIGHLIGHTED[1],
            });
          },
          mouseOut() {
            // eslint-disable-next-line react/no-this-in-sfc
            this.update({
              color: COLORS_DEFAULT[1],
            });
          },
        },
      }] : []),
    ],
  }), [
    i18n.legend.averageReceived,
    i18n.legend.averageSent,
    i18n.legend.received,
    i18n.legend.sent,
    i18n.yAxis,
    i18n.xAxis.categories,
    isMobile,
    isMultipleFaxUsage,
    isZeroLineOnBottom,
    maxAxisY,
    numCategories,
    pointStartAverage,
    received,
    selectedYear,
    sent,
    widthLegend,
  ]);

  return (
    <GenericChart
      data-testid="usage-fax"
      error={error}
      loading={loading}
      onRetry={onRetry}
      tooltip={tooltip}
      options={options}
      noData={noData}
      i18n={{
        error: i18n.error,
        retry: i18n.retry,
        noDataAvailable: i18n.noDataAvailable,
      }}
    />
  );
};

FaxChart.defaultProps = {
  noData: false,
  pointStartAverage: 0,
};

FaxChart.propTypes = {
  chartData: PropTypes.shape({
    sent: PropTypes.shape({
      counters: PropTypes.arrayOf(PropTypes.number).isRequired,
      average: PropTypes.number.isRequired,
    }),
    received: PropTypes.shape({
      counters: PropTypes.arrayOf(PropTypes.number).isRequired,
      average: PropTypes.number.isRequired,
    }),
    maxAxisY: PropTypes.number,
  }).isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  noData: PropTypes.bool,
  onRetry: PropTypes.func.isRequired,
  selectedYear: PropTypes.number.isRequired,
  pointStartAverage: PropTypes.number,
  i18n: PropTypes.shape({
    legend: PropTypes.shape({
      sent: PropTypes.string.isRequired,
      received: PropTypes.string.isRequired,
      averageSent: PropTypes.string.isRequired,
      averageReceived: PropTypes.string.isRequired,
    }).isRequired,
    xAxis: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    yAxis: PropTypes.string.isRequired,
    tooltip: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      titleFormatter: PropTypes.func,
      averageTitleFormatter: PropTypes.func,
    }).isRequired,
    error: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    noDataAvailable: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default FaxChart;
