import { useCallback } from 'react';
import { FirmwareUpdateConfiguration } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import pkg from '../../package.json';

export default function useSetFwUpdCfgApiCall({ authProvider, stack }) {
  const apiCall = useCallback(
    async ({ deviceUuid, updateType }) => {
      const client = new FirmwareUpdateConfiguration(stack, authProvider);
      const response = await client.setFirmwareUpdateConfig(
        deviceUuid,
        updateType,
        {
          app: pkg.name,
          platform: 'consumer-shell',
          version: pkg.version
        }
      );
      return response;
    },
    [authProvider, stack]
  );

  return useApiCall({ apiCall, init: false });
}
