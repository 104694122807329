import React from 'react';
import { ProgressIndicator, IconReload } from '@veneer/core';
import { useI18n } from '@jarvis/react-portal-addons';
import { Content, Message, Loader, Button } from './styles';

const RefreshButton = ({
  onClick,
  isRefreshing,
  hasError = false,
  disabled,
  refreshingMessageId = 'myPrinters.headers.button.refreshingMessage',
  errorMessageId = 'myPrinters.headers.button.refreshErrorMessage'
}) => {
  const { t } = useI18n();
  if (isRefreshing) {
    return (
      <Content>
        <Message className="label-small">{t(refreshingMessageId)}</Message>
        <Loader>
          <ProgressIndicator />
        </Loader>
      </Content>
    );
  }
  return (
    <Content>
      {hasError && <Message>{t(errorMessageId)}</Message>}
      <Button
        className="refresh-button"
        onClick={onClick}
        disabled={disabled}
        data-testid="printers-refresh-btn-pp"
      >
        <IconReload />
      </Button>
    </Content>
  );
};

export default RefreshButton;
