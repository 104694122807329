import { useCallback, useEffect, useState } from 'react';

export default function useApiCall({
  apiCall,
  fetch,
  initialData,
  initialFetch = true,
  init = true,
  params,
}) {
  const [state, setState] = useState({
    data: initialData,
    error: null,
    pending: init && initialFetch,
    success: null,
  });

  const makeApiCall = useCallback(async opts => {
    try {
      setState(previous => ({
        ...previous,
        pending: true,
        error: null,
        success: null,
      }));

      const output = apiCall ? await apiCall(opts || params) : await fetch();

      setState(previous => ({
        ...previous,
        data: output,
        pending: false,
        success: true,
      }));
    } catch (err) {
      setState(previous => ({
        ...previous,
        error: err,
        pending: false,
        success: false,
      }));
      console.error(err);
    }
  }, [apiCall, fetch, params]);

  useEffect(() => {
    if (init && initialFetch) {
      makeApiCall();
    }
  }, [initialFetch, init, makeApiCall]);

  return {
    ...state,
    forceFetch: makeApiCall,
    isFetching: state.pending,
    makeApiCall,
  };
}
