import React, { useEffect, useState } from 'react';
import {
  BorderType,
  ColorMapping,
  LevelForState,
  LevelState,
  SupplyName,
  SupplyState
} from './constants';
import { SupplyContainer } from './styles';
import Dot from './Dot';

const Supply = ({ supply }) => {
  const isSet = (value) =>
    value !== undefined && value !== null && value !== '';
  const [supplyName] = useState(ColorMapping[supply.name] || supply.name);
  const [borderStyle, setBorderStyle] = useState(BorderType.none);
  const [levelState] = useState(supply.levelState || LevelState.unknown);
  const [level, setLevel] = useState(
    (!isSet(supply.level) && isSet(supply.levelState)
      ? LevelForState[supply.levelState]
      : supply.level) || 0
  );
  useEffect(() => {
    if (
      supply.supplyState === SupplyState.error ||
      supply.level === 0 ||
      supply.levelState === LevelState.depleted ||
      (supply.level === undefined && supply.levelState === undefined)
    ) {
      setBorderStyle(BorderType.error);
    } else if (
      supply.isRefill ||
      supply.supplyState === SupplyState.warning ||
      supply.levelState === LevelState.veryLow ||
      supply.levelState === LevelState.veryVeryLow
    ) {
      setBorderStyle(BorderType.warning);
    }

    if (
      supply.supplyState === SupplyState.error ||
      supply.isRefill ||
      (supply.level === undefined && supply.levelState === undefined)
    ) {
      setLevel(0);
    }
  }, [supply.isRefill, supply.level, supply.levelState, supply.supplyState]);

  return (
    <SupplyContainer
      level={level}
      levelState={levelState}
    >
      {supplyName === SupplyName.tricolor && (
        <>
          <div className={`container ${SupplyName.tricolor} ${borderStyle}`}>
            <div className={`bar ${SupplyName.cyan}`}>
              <div className="level">
                <div className="grad25"></div>
                <div className="grad50"></div>
                <div className="grad75"></div>
              </div>
            </div>
            <div className={`bar ${SupplyName.magenta}`}>
              <div className="level">
                <div className="grad25"></div>
                <div className="grad50"></div>
                <div className="grad75"></div>
              </div>
            </div>
            <div className={`bar ${SupplyName.yellow}`}>
              <div className="level">
                <div className="grad25"></div>
                <div className="grad50"></div>
                <div className="grad75"></div>
              </div>
            </div>
          </div>
          <Dot supplyName={SupplyName.tricolor} />
        </>
      )}
      {supplyName !== SupplyName.tricolor && (
        <>
          <div className={`container ${borderStyle}`}>
            <div className={`bar ${supplyName}`}>
              <div className="level">
                <div className="grad25"></div>
                <div className="grad50"></div>
                <div className="grad75"></div>
              </div>
            </div>
          </div>
          <Dot supplyName={supplyName} />
        </>
      )}
    </SupplyContainer>
  );
};

export default Supply;
