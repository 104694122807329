import React, { useCallback } from 'react';
import { DeviceCacheApiClient } from '@jarvis/web-stratus-client';
import { useApiCall, useRootContext } from '@jarvis/react-portal-addons';
import {
  getPrinterHealth,
  getPrinterLastSeenTime,
  getSecurityAssessmentResult,
  isPrinterDisconnected,
  isLegacy,
  hasPrintOnTheGo
} from 'src/utils/utils';
import { useDeviceApiAsDeviceCache } from './useDeviceApiAsDeviceCache';

export default function useDevicesApiCall({ authProvider, stack }) {
  const { featureFlags } = useRootContext();
  const useDeviceCache = featureFlags?.useReactFeatureFlag?.(
    React,
    'ucde-portal',
    {
      key: 'useDeviceCache',
      defaultValue: false
    }
  );
  const { getDevices } = useDeviceApiAsDeviceCache({
    authProvider,
    stack
  });

  const apiCall = useCallback(async () => {
    let response = [];
    if (useDeviceCache) {
      const client = new DeviceCacheApiClient(stack, authProvider);
      const options = {
        params: {
          limit: 200
        }
      };
      response = await client.list(options);
    } else {
      response = await getDevices();
    }
    return response.data?.map?.((device) => ({
      ...device,
      isLegacy: isLegacy(device),
      hasPrintOnTheGo: hasPrintOnTheGo(device),
      printerHealth: getPrinterHealth(device),
      printerIsDisconnected: isPrinterDisconnected(device),
      printerLastSeenTime: getPrinterLastSeenTime(device),
      securityAssessmentResult: getSecurityAssessmentResult(device)
    }));
  }, [useDeviceCache, stack, authProvider, getDevices]);

  return useApiCall({ apiCall });
}
