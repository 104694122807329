import React from 'react';
import { SupplyName } from './constants';
import { SupplyDot } from './styles';

const Dot = ({ supplyName }) => {
  if (supplyName === SupplyName.tricolor) {
    return (
      <SupplyDot>
        <div className={`dot ${SupplyName.tricolor}`}>
          <div className={SupplyName.cyan}></div>
          <div className={SupplyName.magenta}></div>
          <div className={SupplyName.yellow}></div>
        </div>
      </SupplyDot>
    );
  }
  return (
    <SupplyDot>
      <div className="dot">
        <div className={supplyName}></div>
      </div>
    </SupplyDot>
  );
};

export default Dot;
