import { useCallback } from 'react';
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useDeleteDeviceApiCall({ authProvider, stack }) {
  const apiCall = useCallback(
    async ({ accountId, deviceId, ignoreWarning = false }) => {
      const client = new AccountMgtSvcClient(stack, authProvider);
      let options = undefined;
      if (ignoreWarning) {
        options = {
          params: {
            ignoreWarning: true
          }
        };
      }
      const response = await client.deleteDevice(accountId, deviceId, options);
      return response;
    },
    [authProvider, stack]
  );

  return useApiCall({
    apiCall,
    init: false
  });
}
