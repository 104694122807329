import { Stack } from '@jarvis/web-stratus-client';
import {
  PRINTER_BIZ_MODEL,
  STATUS
} from '../components/DeviceStatus/statusDictionary';
import { Entitlement, EntitlementStateSet } from './entitlements';
import { parseDate } from './date';

export const getLegacyMyPrintersURL = (stack, shell) => {
  // TODO: how to handle chinese stage and prod domains?
  const urlByStack = {
    [Stack.dev]: 'https://www.hpsmartdev.com/myprinters',
    [Stack.pie]: 'https://www.hpsmartpie.com/myprinters',
    [Stack.stage]: 'https://www.hpsmartstage.com/myprinters',
    [Stack.prod]: 'https://www.hpsmart.com/myprinters'
  };

  const tenantId = shell?.tenantHandlerInterface?.getTenantId();
  const url = urlByStack[stack] || urlByStack[Stack.prod];
  return `${url}?tenantId=${tenantId}`;
};

export const getCecUrl = (stack) => {
  const urlByStack = {
    [Stack.dev]: 'https://cec.hpsmartdev.com',
    [Stack.pie]: 'https://cec.hpsmartpie.com',
    [Stack.stage]: 'https://cec.hpsmartstage.com',
    [Stack.prod]: 'https://cec.hpsmart.com'
  };
  return urlByStack[stack] || urlByStack[Stack.prod];
};

export const isMyAccountDomain = () =>
  window.location.hostname.includes('myaccount.hp.com');

export const getFullUrl = (path) => {
  const { protocol, host } = window.location;
  return `${protocol}//${host}${path}`;
};

export const isPrinterDisconnected = (device) =>
  device?.identity?.bizModel === PRINTER_BIZ_MODEL.HP_PLUS &&
  device?.status?.connectionState === STATUS.CONNECTION.offline;

export const hasNonOriginalSupply = (device) =>
  device?.supplies?.consumables?.some((consumable) => !consumable.isGenuineHP);

export const getPrinterHealth = (device) => {
  if (isLegacy(device)) {
    // STATUS.HEALTH.not_available
    // Legacy device health status is not displayed
    return null;
  }
  if (!device?.status) {
    return STATUS.HEALTH.unknown;
  }
  if (
    device.status.acceptingJobs === false ||
    device.status.printerStateSeverity === STATUS.HEALTH.SEVERITY.ERROR
  ) {
    return STATUS.HEALTH.stopped;
  }
  if (device.status.printerState === STATUS.HEALTH.STATE.IDLE) {
    return STATUS.HEALTH.good;
  }
  return STATUS.HEALTH.needs_attention;
};

const subscribedServiceIds = [
  Entitlement.InkDelivery,
  Entitlement.InstantInk,
  Entitlement.PrintPlans
];
const validStates = EntitlementStateSet.Active;
const eligibleServiceIds = [Entitlement.InstantInk, Entitlement.PrintPlans];

export const getSubscriptionStatus = (programInfo) => {
  if (
    programInfo?.entitlementList?.find(
      (entitlement) =>
        subscribedServiceIds.includes(entitlement.serviceId) &&
        validStates.includes(entitlement.state)
    )
  ) {
    return STATUS.SUBSCRIPTION.SUBSCRIBED;
  }
  if (
    programInfo?.subscriptionOfferingList?.find((entitlement) =>
      eligibleServiceIds.includes(entitlement.serviceId)
    )
  ) {
    return STATUS.SUBSCRIPTION.ELIGIBLE;
  }
  return STATUS.SUBSCRIPTION.NON_ELIGIBLE;
};

export const getSubscriptionUpdatePlan = (programInfo, grants) => {
  if (
    grants?.contents?.find(
      (grant) => grant.grant === Entitlement.PrinterAsAService
    )
  ) {
    return STATUS.UPDATE_PLAN.HP_ONE_PRINTERS;
  }
  if (
    programInfo?.entitlementList?.find(
      (entitlement) =>
        eligibleServiceIds.includes(entitlement.serviceId) &&
        validStates.includes(entitlement.state)
    )
  ) {
    return STATUS.UPDATE_PLAN.INSTANT_INK;
  }
  return STATUS.UPDATE_PLAN.UNKNOWN;
};

export const isLegacy = (device) => {
  return ['gen1', 'gen2'].includes(device?.identity?.platformIdentifier);
};

export const getPrinterLastSeenTime = (device) => {
  return parseDate(device.status?.connectionStateLastUpdatedAt);
};

export const hasPrintOnTheGo = (device) => {
  return device?.solutions?.some(
    (solution) => solution === Entitlement.PrintOnTheGo
  );
};

export const getSecurityAssessmentResult = (device) => {
  const hasSmartSecurity = device?.solutions?.some(
    (solution) => solution === Entitlement.SmartSecurity
  );
  if (!hasSmartSecurity) {
    return null;
  }
  const data = device?.solutionMetadata?.g1PC7Nw3GtkekSaPOKBrEoVkw9dKbpjo;
  if (data?.state === 'disabled' || data?.result === 'notMonitored') {
    return STATUS.SECURITY.notMonitored;
  }
  if (data?.result === 'securityMonitored') {
    return STATUS.SECURITY.securityMonitored;
  }
  if (data?.result === 'needsAttention') {
    return STATUS.SECURITY.needsAttention;
  }
  if (data?.result === 'unknown') {
    return STATUS.SECURITY.unknown;
  }
  return STATUS.SECURITY.unknown; // fallback
};

const ONE_TAG_MATCHER = '(%placeholder%)';
const TWO_TAG_MATCHER = '%start%(.*)%end%';

/**
 * Replaces the placeholders within text with the jsx provided in the matches array.
 * @param {string} text: text to be parsed, containing placeholders to be replaced
 * example 1: 'Hello {{tagReplaceStart}}world{{tagReplaceEnd}}!'
 * example 2: 'Hello {{tagReplace}}!'
 * example 3: '{{tagReplace}} {{tagReplaceStart}}world{{tagReplaceEnd}}!'
 * @param {array} matches: array of objects with the following structure:
 * {
 *   start: string,
 *   end: string,
 *   placeholder: string,
 *   jsx: jsx element or function that returns jsx element or string
 * }
 * example: [
 * {
 *   start: '{{tagReplaceStart}}',
 *   end: '{{tagReplaceEnd}}',
 *   jsx: (text) => <span>{text}</span>
 * },
 * {
 *   placeholder: '{{tagReplace}}',
 *   jsx: 'Hello'
 * }
 * @returns {array} array of strings and jsx elements
 */
export const replaceWithJsx = (text, matches) => {
  const regExp = matches
    .map((match) => {
      const { start, end, placeholder } = match;
      if (start && end) {
        return TWO_TAG_MATCHER.replace('%start%', start).replace('%end%', end);
      }
      return ONE_TAG_MATCHER.replace('%placeholder%', placeholder);
    })
    .join('|');
  const tagMatcher = new RegExp(regExp, 'g');
  return text
    .split(tagMatcher)
    .map((value, index) => {
      const match = index % (matches.length + 1);
      let jsx = undefined;
      if (match && value) {
        jsx = matches[match - 1].jsx;
      }
      if (jsx) {
        if (typeof jsx === 'function') {
          return jsx(value);
        }
        return jsx;
      }
      return value;
    })
    .filter((content) => content);
};
