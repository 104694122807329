import React from 'react';
import PrintersPage from './components/PrintersPage';
import PrintersTenantPage from './components/PrintersTenantPage';
import ActivateHpPlusPage from './components/ActivateHpPlusPage';
import './styles/global.scss';

const App = ({ ...props }) => {
  const { properties: { page } = { page: null } } = props;

  if (page === 'printers-tenant') {
    return <PrintersTenantPage />;
  }
  if (page === 'activate-hp-plus') {
    return <ActivateHpPlusPage />;
  }
  return <PrintersPage />;
};

export default App;
