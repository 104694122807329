import {
  consumableStateReason as ConsumableStateReason,
  printerStateReason as PrinterStateReason,
  suppressedStateReasons,
  transformConsumableStateReasons,
  transformStateReasons
} from './statusDictionary';

export const getStateReasons = (device) => {
  const reasons = device?.status?.printerStateReasons || [];
  return reasons
    .map(
      (reason) =>
        transformStateReasons[reason.toLowerCase()]?.transform({
          supplyDelivery: device?.identity?.supplyDelivery
        }) || reason
    ) // transform reasons, mapping according to cartridgeType
    .filter(
      (reason, index, self) =>
        !suppressedStateReasons.includes(reason.toLowerCase()) && // remove suppressed
        !!PrinterStateReason[reason.toLowerCase()] && // remove invalid
        self.indexOf(reason) === index // remove synonyms/duplicates
    )
    .map((reason) => {
      const stateReason = PrinterStateReason[reason.toLowerCase()];
      const colorCodes = stateReason?.colorCodeFilter
        ? device?.supplies?.consumables
            ?.filter((supply) => stateReason?.colorCodeFilter(supply))
            ?.map((supply) => ({
              code: supply?.colorCode,
              order: supply?.slotOrder || 0
            }))
            ?.sort((a, b) => a.order - b.order)
        : undefined;
      return {
        status: reason,
        ...stateReason,
        colorCodes,
        order: stateReason.type + 1
      };
    });
};

export const getConsumablesStateReasons = (device, subscription) =>
  device?.supplies?.consumables
    // transform reasons, mapping accoring to consumablePlatform
    ?.map((supplyState) => ({
      colorCode: supplyState.colorCode,
      slotOrder: supplyState.slotOrder,
      stateReasons: supplyState.stateReasons
        ?.map(
          (reason) =>
            transformConsumableStateReasons[reason]?.transform({
              consumablePlatform: supplyState?.consumablePlatform,
              bizModel: device?.identity?.bizModel,
              subscriptionState: subscription,
              supplyType: supplyState?.supplyType
            }) || reason
        )
        ?.filter((reason, index, self) => self.indexOf(reason) === index)
    }))

    // reduce consumables to an array where every item is a state reason with color codes and slot order
    ?.reduce(
      (prev, consumable) => [
        ...prev,
        ...(consumable?.stateReasons?.map((reason) => ({
          reason,
          colorCode: {
            code: consumable?.colorCode,
            order: consumable?.slotOrder || 0
          }
        })) || [])
      ],
      []
    )

    // filter reasons that we do not support
    ?.filter(({ reason }) => !!ConsumableStateReason[reason])

    // remove duplicate reasons, aggregate color codes
    ?.reduce((prev, cur) => {
      const reasonIndex = prev.findIndex((item) => item.reason === cur.reason);
      if (reasonIndex >= 0) {
        return [
          ...prev.slice(0, reasonIndex),
          {
            reason: cur.reason,
            colorCodes: [...prev[reasonIndex].colorCodes, cur.colorCode] // aggregates consumables color codes with same state reason
          },
          ...prev.slice(reasonIndex + 1) // out of bounds slices will return an empty array
        ];
      }

      return [
        ...prev,
        {
          reason: cur.reason,
          colorCodes: cur.colorCode ? [cur.colorCode] : []
        }
      ];
    }, [])

    // sort color codes by slot order
    ?.map((item) => ({
      ...item,
      colorCodes: item.colorCodes.sort((a, b) => a.order - b.order)
    }))

    // map to an object that contains the notification information (title, description, etc)
    ?.map(({ reason, colorCodes }) => {
      const stateReason = ConsumableStateReason[reason];
      return {
        ...stateReason,
        status: reason,
        colorCodes,
        order: stateReason.type
      };
    }) || [];
