import styled from 'styled-components';
import tokens from '@veneer/tokens';
import {
  IconCheckCircle,
  IconInfo,
  IconMinusCircle,
  IconShieldCheck,
  IconShieldOff,
  IconShieldWarning,
  IconWarningAlt,
  IconXCircle,
  IconHelp
} from '@veneer/core';

export const IconInformation = styled(IconInfo)`
  && {
    fill: #212121; // colorGray10
  }
`;

export const IconPrinterEnabled = styled(IconCheckCircle)`
  && {
    fill: #08a622; // colorGreen6
  }
`;

export const IconPrinterWarning = styled(IconWarningAlt)`
  && {
    fill: #e8701a; // colorOrange6
  }
`;

export const IconPrinterDisabled = styled(IconXCircle)`
  && {
    fill: #bf1212; // colorRed6
  }
`;

export const IconSecurityMonitored = styled(IconShieldCheck)`
  && {
    fill: #1c7a17; // colorGreen6
  }
`;

export const IconSecurityNotMonitored = styled(IconShieldOff)`
  && {
    fill: #adadad; // colorGray4
  }
`;

export const IconSecurityNeedsAttention = styled(IconShieldWarning)`
  && {
    fill: #d16607; // colorOrange6
  }
`;

export const IconSecurityUnavailable = styled(IconShieldOff)`
  && {
    fill: #404040; // colorGray7
  }
`;

export const IconPrinterError = styled(IconXCircle)`
  && {
    fill: #404040; // colorGray7
  }
`;

export const IconWarningAltOrange = styled(IconWarningAlt)`
  && {
    fill: #e8701a; // colorOrange6
  }
  min-width: 24px;
`;

export const IconMinusCircleRed = styled(IconMinusCircle)`
  && {
    fill: #bf1212; // colorRed6
  }
  min-width: 24px;
`;

export const IconMinusCircleDarkGray = styled(IconMinusCircle)`
  && {
    fill: ${tokens.colorGray10};
  }
  min-width: 24px;
`;

export const IconMinusCircleGray = styled(IconMinusCircle)`
  && {
    fill: ${tokens.colorGray4};
  }
  min-width: 24px;
`;

export const IconCheckCircleGreen = styled(IconCheckCircle)`
  && {
    fill: #08a622; // colorGreen6
  }
`;

export const IconHelpBlue = styled(IconHelp)`
  && {
    fill: ${tokens.colorHpBlue6};
  }
  min-width: 24px;
`;

export const PrinterStatus = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  letter-spacing: 0;
  color: ${tokens.colorGray7};
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 1036px) {
    font-size: 12px;
    line-height: 24px;
  }

  && > svg {
    vertical-align: bottom;
    margin-right: 4px;
    width: 16px;
    height: 16px;
    min-width: 16px;

    @media (min-width: 1036px) {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      min-width: 24px;
    }
  }
`;

export const DetailContainer = styled.div`
  ${PrinterStatus} {
    font-size: 16px;

    @media (min-width: 1036px) {
      font-size: 20px;
    }
  }
`;

export const CoachmarkContainer = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const Explanation = styled.div`
  margin-left: 20px;
  font-size: 14px;

  @media (min-width: 1036px) {
    margin-left: 34px;
    font-size: 16px;
  }

  a {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    :hover {
      color: ${tokens.colorHpBlue7};
      text-decoration: underline;
    }
    :active {
      color: ${tokens.colorHpBlue8};
      text-decoration: underline;
    }
  }
`;
