import { useCallback } from 'react';
import { DeviceTransferClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useDeviceTransferApiCall({ authProvider, stack }) {
  const apiCall = useCallback(
    async ({ deviceId, fromAccountId, toAccountId, userId }) => {
      const client = new DeviceTransferClient(stack, authProvider);
      const options = {
        deviceId,
        fromAccountId,
        toAccountId,
        userId
      };
      const response = await client.updateOwenrship(options);
      return response.data;
    },
    [authProvider, stack]
  );

  return useApiCall({
    apiCall,
    init: false
  });
}
