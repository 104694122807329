import { LevelState } from 'src/components/Supplies/constants';
import {
  IconCheckCircleGreen,
  IconInformation,
  IconMinusCircleDarkGray,
  IconMinusCircleGray,
  IconMinusCircleRed,
  IconPrinterDisabled,
  IconPrinterEnabled,
  IconPrinterError,
  IconPrinterWarning,
  IconSecurityMonitored,
  IconSecurityNeedsAttention,
  IconSecurityNotMonitored,
  IconSecurityUnavailable,
  IconWarningAltOrange
} from './styles';

export const TYPES = {
  CRITICAL: 0,
  WARNING: 1,
  GOOD: 2,
  INFO: 3
};

export const PRINTER_BIZ_MODEL = {
  HP_PLUS: 'E2E'
};

export const SUPPLY_DELIVERY = {
  TANK: 'tank',
  REGULAR: 'regular'
};

export const SUPPLY_TYPES = {
  TONER: 'toner'
};

export const STATUS = {
  GROUP: {
    reason: 'reason',
    health: 'health',
    healthTenant: 'healthTenant',
    connection: 'connection',
    security: 'security',
    general: 'general',
    subscription: 'subscription'
  },
  GENERAL: {
    disconnected_from_internet: 'disconnected_from_internet',
    not_original_inks: 'not_original_inks'
  },
  PRINTER: {
    none: 'none',
    other: 'other',
    not_mapped: 'not_mapped',
    media_needed: 'media_needed',
    media_jam: 'media_jam',
    paused: 'paused',
    shutdown: 'shutdown',
    toner_low: 'toner_low',
    toner_emtpy: 'toner_empty',
    spool_full: 'spool_full',
    door_open: 'door_open',
    cover_open: 'cover_open',
    media_empty: 'media_empty',
    marker_supply_low: 'marker_supply_low',
    marker_supply_empty: 'marker_supply_empty',
    media_low: 'media_low',
    TRANSFORMED: {
      tank_supply_empty: 'tank_supply_empty',
      tank_supply_low: 'tank_supply_low'
    }
  },
  HEALTH: {
    stopped: 'stopped',
    needs_attention: 'needs_attention',
    good: 'good',
    unknown: 'unknown',
    not_available: 'not_available',
    SEVERITY: {
      NONE: 'NONE',
      REPORT: 'REPORT',
      WARNING: 'WARNING',
      ERROR: 'ERROR'
    },
    STATE: {
      NOT_A_PRINTER: 'NOT_A_PRINTER',
      IDLE: 'IDLE',
      PROCESSING: 'PROCESSING',
      STOPPED: 'STOPPED'
    }
  },
  CONNECTION: {
    online: 'online',
    offline: 'offline'
  },
  SECURITY: {
    securityMonitored: 'securityMonitored',
    needsAttention: 'needsAttention',
    notMonitored: 'notMonitored',
    unknown: 'unknown'
  },
  SUPPLY: {
    dynamicIntegrityViolationError: 'dynamicIntegrityViolationError',
    TRANSFORMED: {
      supplyProblemII: 'supplyProblemII',
      supplyProblemTrade: 'supplyProblemTrade',
      cartridgeProblemII: 'cartridgeProblemII',
      cartridgeProblemTrade: 'cartridgeProblemTrade',
      nonHpCircuitryDetected: 'nonHpCircuitryDetected',
      nonHpChipDetected: 'nonHpChipDetected'
    }
  },
  SUBSCRIPTION: {
    SUBSCRIBED: 'subscribed',
    ELIGIBLE: 'eligible',
    NON_ELIGIBLE: 'nonEligible'
  },
  UPDATE_PLAN: {
    HP_ONE_PRINTERS: 'hpOnePrinters',
    INSTANT_INK: 'instantInk',
    UNKNOWN: 'unknown'
  },
  printer_error: 'printer_error'
};

export const transformConsumableStateReasons = {
  dynamicIntegrityViolationError: {
    transform: ({
      consumablePlatform,
      bizModel,
      subscriptionState,
      supplyType
    }) => {
      const isIph = ['iph', 'cissIph'].includes(consumablePlatform);
      const isHpPlus = bizModel === PRINTER_BIZ_MODEL.HP_PLUS;
      const isInstantInk = subscriptionState === STATUS.SUBSCRIPTION.SUBSCRIBED;
      const isLaser = supplyType === SUPPLY_TYPES.TONER;
      if (isHpPlus) {
        if (isLaser) {
          if (isInstantInk) {
            // HP+ and II and Laser
            return STATUS.SUPPLY.TRANSFORMED.supplyProblemII;
          }
          // HP+ and Trade and Laser
          return STATUS.SUPPLY.TRANSFORMED.supplyProblemTrade;
        }
        if (isInstantInk) {
          // HP+ and II and Ink
          return STATUS.SUPPLY.TRANSFORMED.cartridgeProblemII;
        }
        // HP+ and Trade and Ink
        return STATUS.SUPPLY.TRANSFORMED.cartridgeProblemTrade;
      }
      if (isIph) {
        // Flex and (Trade or II) and IPH
        return STATUS.SUPPLY.TRANSFORMED.nonHpCircuitryDetected;
      }
      // Flex and (Trade or II) and (Laser or Ink)
      return STATUS.SUPPLY.TRANSFORMED.nonHpChipDetected;
    }
  }
};

export const consumableStateReason = {
  cartridgeProblemII: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.consumableStateReason.cartridgeProblemII.shortMessage',
    message: 'myPrinters.consumableStateReason.cartridgeProblemII.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      lineBreak: '<br />'
    },
    colorCodes: []
  },
  cartridgeProblemTrade: {
    Icon: IconMinusCircleRed,
    title:
      'myPrinters.consumableStateReason.cartridgeProblemTrade.shortMessage',
    message:
      'myPrinters.consumableStateReason.cartridgeProblemTrade.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      lineBreak: '<br />'
    },
    colorCodes: []
  },
  nonHpChipDetected: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.consumableStateReason.nonHpChipDetected.shortMessage',
    message: 'myPrinters.consumableStateReason.nonHpChipDetected.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      link: '<a href="https://www.hp.com/learn/ds" target="_blank">www.hp.com/learn/ds</a>'
    },
    colorCodes: []
  },
  nonHpCircuitryDetected: {
    Icon: IconMinusCircleRed,
    title:
      'myPrinters.consumableStateReason.nonHpCircuitryDetected.shortMessage',
    message:
      'myPrinters.consumableStateReason.nonHpCircuitryDetected.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      link: '<a href="https://www.hp.com/learn/ds" target="_blank">www.hp.com/learn/ds</a>'
    },
    colorCodes: []
  },
  supplyProblemII: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.consumableStateReason.supplyProblemII.shortMessage',
    message: 'myPrinters.consumableStateReason.supplyProblemII.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      lineBreak: '<br />'
    },
    colorCodes: []
  },
  supplyProblemTrade: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.consumableStateReason.supplyProblemTrade.shortMessage',
    message: 'myPrinters.consumableStateReason.supplyProblemTrade.longMessage',
    type: TYPES.CRITICAL,
    formatParams: {
      lineBreak: '<br />'
    },
    colorCodes: []
  }
};

export const suppressedStateReasons = [
  STATUS.PRINTER.none,
  STATUS.PRINTER.other,
  STATUS.PRINTER.not_mapped,
  STATUS.PRINTER.media_needed,
  STATUS.PRINTER.media_empty
];

export const transformStateReasons = {
  marker_supply_low: {
    transform: ({ supplyDelivery }) => {
      return supplyDelivery === SUPPLY_DELIVERY.TANK
        ? STATUS.PRINTER.TRANSFORMED.tank_supply_low
        : null;
    }
  },
  marker_supply_empty: {
    transform: ({ supplyDelivery }) => {
      return supplyDelivery === SUPPLY_DELIVERY.TANK
        ? STATUS.PRINTER.TRANSFORMED.tank_supply_empty
        : null;
    }
  }
};

export const printerStateReason = {
  none: undefined,
  media_needed: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.mediaNeeded.shortMessage',
    message: 'myPrinters.stateReason.mediaNeeded.longMessage',
    type: TYPES.WARNING
  },
  media_jam: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.mediaJam.shortMessage',
    message: 'myPrinters.stateReason.mediaJam.longMessage',
    type: TYPES.CRITICAL
  },
  paused: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.paused.shortMessage',
    message: 'myPrinters.stateReason.paused.longMessage',
    type: TYPES.INFO
  },
  shutdown: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.shutdown.shortMessage',
    message: 'myPrinters.stateReason.shutdown.longMessage',
    type: TYPES.WARNING
  },
  toner_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.tonerLow.shortMessage',
    message: 'myPrinters.stateReason.tonerLow.longMessage',
    type: TYPES.INFO,
    colorCodeFilter: (supply) => supply.levelState === LevelState.low
  },
  toner_empty: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.tonerEmpty.shortMessage',
    message: 'myPrinters.stateReason.tonerEmpty.longMessage',
    type: TYPES.CRITICAL,
    colorCodeFilter: (supply) => supply.levelState === LevelState.depleted
  },
  spool_full: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.spoolFull.shortMessage',
    message: 'myPrinters.stateReason.spoolFull.longMessage',
    type: TYPES.INFO
  },
  door_open: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.doorOpen.shortMessage',
    message: 'myPrinters.stateReason.doorOpen.longMessage',
    type: TYPES.CRITICAL
  },
  cover_open: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.coverOpen.shortMessage',
    message: 'myPrinters.stateReason.coverOpen.longMessage',
    type: TYPES.CRITICAL
  },
  media_empty: {
    Icon: IconWarningAltOrange,
    title: 'myPrinters.stateReason.mediaEmpty.shortMessage',
    message: 'myPrinters.stateReason.mediaEmpty.longMessage',
    type: TYPES.WARNING
  },
  marker_supply_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.markerSupplyLow.shortMessage',
    message: 'myPrinters.stateReason.markerSupplyLow.longMessage',
    type: TYPES.INFO,
    colorCodeFilter: (supply) => supply.levelState === LevelState.low
  },
  marker_supply_empty: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.markerSupplyEmpty.shortMessage',
    message: 'myPrinters.stateReason.markerSupplyEmpty.longMessage',
    type: TYPES.CRITICAL,
    colorCodeFilter: (supply) => supply.levelState === LevelState.depleted
  },
  tank_supply_empty: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.stateReason.tankSupplyEmpty.shortMessage',
    message: 'myPrinters.stateReason.tankSupplyEmpty.longMessage',
    type: TYPES.CRITICAL,
    colorCodeFilter: (supply) => supply.levelState === LevelState.depleted
  },
  tank_supply_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.tankSupplyLow.shortMessage',
    message: 'myPrinters.stateReason.tankSupplyLow.longMessage',
    type: TYPES.INFO,
    colorCodeFilter: (supply) => supply.levelState === LevelState.low
  },
  media_low: {
    Icon: IconInformation,
    title: 'myPrinters.stateReason.mediaLow.shortMessage',
    message: 'myPrinters.stateReason.mediaLow.longMessage',
    type: TYPES.INFO
  }
};

const statusHealth = {
  stopped: {
    Icon: IconPrinterDisabled,
    title: 'home.status.item3.state.red',
    type: TYPES.CRITICAL
  },
  needs_attention: {
    Icon: IconPrinterWarning,
    title: 'home.status.item3.state.yellow',
    type: TYPES.WARNING
  },
  good: {
    Icon: IconPrinterEnabled,
    title: 'home.status.item3.state.green',
    type: TYPES.GOOD
  },
  unknown: {
    Icon: IconWarningAltOrange,
    title: 'home.status.unknown.title',
    type: TYPES.WARNING
  },
  not_available: {
    Icon: IconMinusCircleGray,
    title: 'homev2.printersTenantCard.informationNotAvailable',
    type: TYPES.CRITICAL,
    coachmark: 'homev2.printersTenantCard.oldPrinterTooltip'
  }
};

const statusHealthTenant = {
  stopped: {
    Icon: IconMinusCircleRed,
    title: 'home.status.item3.state.red',
    type: TYPES.CRITICAL
  },
  needs_attention: {
    Icon: IconWarningAltOrange,
    title: 'home.status.item3.state.yellow',
    type: TYPES.WARNING
  },
  good: {
    Icon: IconCheckCircleGreen,
    title: 'home.status.item3.state.green',
    type: TYPES.GOOD
  },
  unknown: {
    Icon: IconMinusCircleDarkGray,
    title: 'home.status.item.unknown',
    type: TYPES.WARNING
  },
  not_available: {
    Icon: IconMinusCircleGray,
    title: 'homev2.printersTenantCard.informationNotAvailable',
    type: TYPES.CRITICAL,
    coachmark: 'homev2.printersTenantCard.oldPrinterTooltip'
  }
};

const connectionState = {
  online: {
    Icon: IconPrinterEnabled,
    title: 'home.myPrintersCard.item1.state.green',
    type: TYPES.GOOD
  },
  offline: {
    Icon: IconMinusCircleRed,
    title: 'home.myPrintersCard.item1.state.red',
    type: TYPES.CRITICAL
  }
};

const securityState = {
  securityMonitored: {
    Icon: IconSecurityMonitored,
    title: 'home.myPrintersCard.item2.state.green'
  },
  needsAttention: {
    Icon: IconSecurityNeedsAttention,
    title: 'home.myPrintersCard.item2.state.yellow'
  },
  notMonitored: {
    Icon: IconSecurityNotMonitored,
    title: 'home.myPrintersCard.item2.state.gray'
  },
  unknown: {
    Icon: IconSecurityUnavailable,
    title: 'home.myPrintersCard.item2.state.unknown'
  }
};

const subscriptionState = {
  subscribed: {
    Icon: IconPrinterEnabled,
    title: 'myPrinters.subscribed',
    type: TYPES.GOOD
  }
};

const generalState = {
  disconnected_from_internet: {
    Icon: IconMinusCircleRed,
    title: 'myPrinters.printerDetails.disconnectedFromInternet.title',
    message: 'myPrinters.printerDetails.disconnectedFromInternet.message',
    linkText: 'myPrinters.printerDetails.disconnectedFromInternet.linkText',
    linkUrl:
      'https://support.hp.com/us-en/document/ish_1780623-1698506-16?openCLC=true',
    type: TYPES.CRITICAL
  },
  not_original_inks: {
    Icon: IconInformation,
    title: 'myPrinters.printerDetails.nonHpCartridge.title',
    linkText: 'myPrinters.printerDetails.nonHpCartridge.message',
    linkUrl: 'myPrinters.printerDetails.nonHpCartridge.url',
    type: TYPES.INFO
  }
};

const lists = {
  ...printerStateReason,
  ...statusHealth,
  ...connectionState,
  ...securityState,
  ...generalState,
  printer_error: {
    Icon: IconPrinterError,
    title: 'home.status.item.unknown',
    type: TYPES.CRITICAL
  }
};

const groupedStatus = {
  reason: { list: printerStateReason },
  health: { list: statusHealth },
  healthTenant: { list: statusHealthTenant },
  connection: { list: connectionState },
  security: { list: securityState, caseSensitive: true },
  general: { list: generalState },
  subscription: { list: subscriptionState }
};

export const getStatusWithId = (id, { group } = {}) => {
  if (group) {
    const grouped = groupedStatus[group];
    if (grouped) {
      const processedReason = grouped.caseSensitive ? id : id?.toLowerCase();
      const status = grouped.list[processedReason];
      if (status) {
        return status;
      }
    }
    return null;
  }
  return lists[id] || lists[id?.toLowerCase()] || null;
};
