import { useCallback } from 'react';
import { GrantControllerSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';

export default function useGrantsControllerApiCall({
  authProvider,
  stack,
  deviceId,
  accountId,
  serviceId
}) {
  const apiCall = useCallback(async () => {
    const client = new GrantControllerSvcClient(stack, authProvider);
    const response = await client.getGrants({
      tenantId: accountId,
      level: 'DEVICE',
      deviceId,
      grant: serviceId
    });
    return response.data;
  }, [authProvider, stack, deviceId, accountId, serviceId]);

  return useApiCall({ apiCall });
}
