import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Card } from '@veneer/core';

export const StatusItemCard = styled(Card)`
  border-color: transparent;
  padding: 16px;
  @media (min-width: ${tokens.lgMin}) {
    padding: 16px 48px;
  }
`;

export const StatusItemTitle = styled.div`
  font-weight: 700;
  padding-bottom: 8px;
  color: ${tokens.colorGray10};

  &&.spaced {
    padding-top: 24px;
  }
`;

export const StatusItemMessage = styled.div`
  color: ${tokens.colorGray10};
  &&.spaced {
    padding-top: 24px;
  }

  br {
    display: block;
    margin-top: 24px;
    content: ' ';
  }
`;

export const StatusItemGroup = styled.div`
  &&.spaced {
    padding-top: 24px;
  }
  > button {
    @media (min-width: ${tokens.smMin}) {
      margin-top: 24px;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
    @media (max-width: ${tokens.xsMax}) {
      width: 100%;
      margin-top: 24px;
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
`;

export const StatusItem = styled.div`
  padding-bottom: 2px;
  &&.spaced {
    padding-top: 24px;
  }
`;

export const StatusItemLabel = styled.div`
  font-weight: 700;
`;

export const StatusItemDescription = styled.div`
  font-weight: 400;
`;
