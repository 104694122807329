import { useCallback } from 'react';
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client';
import { useApiCall } from '@jarvis/react-portal-addons';
import {
  getEntitlement,
  Entitlement,
  EntitlementStateSet
} from 'src/utils/entitlements';

export default function useAccountProgramInfosCall({
  authProvider,
  stack,
  accountId,
  userId
}) {
  const apiCall = useCallback(async () => {
    const accountMgtSvcClient = new AccountMgtSvcClient(stack, authProvider);
    if (accountId && userId) {
      const response = await accountMgtSvcClient.getProgramInfos(
        accountId,
        userId
      );
      const programInfos = response.data;
      return {
        ...programInfos,
        hasPrintOnTheGo: getEntitlement({
          programInfos,
          entitlement: Entitlement.PrintOnTheGo,
          states: EntitlementStateSet.Enabled
        })
      };
    }
    return undefined;
  }, [accountId, authProvider, stack, userId]);

  return useApiCall({ apiCall });
}
