import styled from 'styled-components';
import tokens from '@veneer/tokens';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: ${tokens.xsMax}) {
    display: grid;
    grid-template-columns: 64px auto 24px;
  }

  > * {
    align-self: center;
  }
`;

export const Icon = styled.div`
  display: flex;

  margin-left: -8px;
  min-height: 54px;
  min-width: 54px;
  width: 54px;
  height: 54px;

  img {
    width: 54px;
    height: 54px;
  }

  grid-row: 1;
  grid-column: 1;
`;

export const ServiceName = styled.div`
  margin-right: ${tokens.space2};
  width: 100%;
  white-space: nowrap;

  @media (max-width: ${tokens.xsMax}) {
    font-size: 14px;
  }
`;

export const SideText = styled.div`
  text-align: right;

  grid-row: 1;
  grid-column: 3;

  padding-right: ${tokens.space2};
  width: 100%;
  white-space: nowrap;

  @media (max-width: ${tokens.xsMax}) {
    font-size: ${tokens.fontSize1};
    grid-row: 2;
    grid-column: 2;
    padding-right: 0;

    text-align: left;
  }
`;

export const CoachmarkContainer = styled.div`
  justify-self: flex-end;
  display: flex;
  align-self: center;
  height: min-content;
  width: min-content;
  align-items: center;
  min-width: 24px;
  min-height: 25px;

  grid-row: 1;
  grid-column: 4;
`;

export const TitleContainer = styled.div`
  display: flex;

  flex-direction: column;

  align-self: center;

  grid-row: 1;
  grid-column: 2;
`;
