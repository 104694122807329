import styled from 'styled-components';
import tokens from '@veneer/tokens';
import { Link } from '@jarvis/react-portal-addons';

export const Container = styled.div`
  color: rgb(70, 77, 80);
  min-height: 100vh;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 40px;
  @media (max-width: ${tokens.xsMax}) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media (max-width: ${tokens.lgMax}) {
    width: auto;
  }
`;

export const SubHeader = styled.div`
  width: 640px;
  p:not(:last-child) {
    padding-bottom: 24px;
  }
  @media (max-width: ${tokens.lgMax}) {
    width: auto;
  }
`;

export const RefreshSection = styled.div`
  margin: 0 0 24px 0;
  display: flex;
  text-align: end;
  justify-content: flex-end;
  @media (max-width: ${tokens.lgMax}) {
    margin-top: 16px;
  }
  @media (max-width: ${tokens.xsMax}) {
    justify-content: flex-start;
  }
`;

export const SubSection = styled.div`
  display: grid;
  grid-template-columns: 640px 300px;
  gap: 16px 32px;
  @media (max-width: ${tokens.lgMax}) {
    grid-template-columns: none;
    gap: 0;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const PrintersHeader = styled.div`
  width: 640px;
`;

export const Title = styled.div`
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  max-width: 720px;
  margin-top: 32px;
`;

export const FooterData = styled.div`
  margin-bottom: ${tokens.space5};
  font-weight: bold;
`;
export const FooterInfo = styled.div`
  > span > a {
    color: ${tokens.colorHpBlue6};
    text-decoration: none;
    letter-spacing: 0.02em;
  }
`;

export const NeedHelp = styled.div`
  margin-left: 20px;
  @media (max-width: ${tokens.lgMax}) {
    display: none;
  }
`;

export const HelpTitle = styled.h6`
  font-family: ${tokens.fontFamilyRegular};
`;

export const Separator = styled.hr`
  border: none;
  border-bottom: 1px solid #ebebeb;
  margin: 5px 0;
`;

export const CustomLink = styled(Link)`
  && {
    font-size: ${tokens.fontSize2};
    font-weight: bolder;
  }
`;
