import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, ProgressIndicator } from '@veneer/core';
import {
  ModalLine,
  ModalButtonGroup,
  ModalContainer,
  RemovePrinterProgress
} from './styles';
import {
  removePrinterModalTypes,
  statusDeleteDeviceErrorCode,
  statusDeleteDeviceErrorMessage
} from './constants';
import PATHS from 'src/constants/paths';
import { useI18n, useRootContext } from '@jarvis/react-portal-addons';
import useDeleteDeviceApiCall from 'src/hooks/useDeleteDeviceApiCall';
import {
  isJWebAndroidApp,
  isJWebEventingPluginValid,
  isJWebiOSApp,
  isJWebDesktopApp,
  dispatchJWebCloseEvent
} from 'src/utils/jweb';
import {
  ModalScreenPath,
  publishButtonClickedEvent,
  publishModalDisplayedEvent
} from 'src/utils/analytics';

const RemovePrinter = ({
  openModal,
  handleCloseModal,
  device,
  refreshData,
  handleOpenRemoveDeviceModal,
  removePrinterModalType,
  removePrinterModalSubType
}) => {
  const { analytics, authProvider, stack, shell } = useRootContext();
  const deleteDevice = useDeleteDeviceApiCall({ authProvider, stack });
  const { t } = useI18n();
  const printerName = device?.identity.makeAndModel.name.replace(/_/g, ' ');

  const analyticsMetadata = useMemo(
    () => ({
      associatedDeviceUuid: device?.identity?.deviceUuid,
      associatedDeviceProductNumber: device?.identity?.makeAndModel?.number
    }),
    [device?.identity?.deviceUuid, device?.identity?.makeAndModel?.number]
  );

  useEffect(() => {
    if (openModal) {
      publishModalDisplayedEvent(
        analytics,
        'RemovePrinterModal',
        analyticsMetadata
      );
    }
  }, [analytics, analyticsMetadata, openModal]);

  useEffect(() => {
    if (
      !deleteDevice.isFetching &&
      removePrinterModalType === removePrinterModalTypes.PRINTER_MODAL_REMOVING
    ) {
      if (deleteDevice.success) {
        handleOpenRemoveDeviceModal(
          device,
          removePrinterModalTypes.PRINTER_MODAL_REMOVED
        );
      }
      if (deleteDevice.error) {
        let errorMessage = statusDeleteDeviceErrorMessage.NOT_MAPPED;
        if (deleteDevice.error.response?.status === 400) {
          const code = deleteDevice.error.response.data.errors?.[0]?.code;
          errorMessage =
            statusDeleteDeviceErrorCode[code] ||
            statusDeleteDeviceErrorMessage.NOT_MAPPED;
        }
        handleCloseModal();
        handleOpenRemoveDeviceModal(
          device,
          removePrinterModalTypes.PRINTER_MODAL_FAILED,
          errorMessage
        );
      }
    }
  }, [
    deleteDevice,
    device,
    handleCloseModal,
    handleOpenRemoveDeviceModal,
    removePrinterModalType
  ]);

  const handleCloseModalSuccess = useCallback(() => {
    handleCloseModal();
    refreshData();
  }, [handleCloseModal, refreshData]);

  const removePrinter = useCallback(
    async (ignoreWarning = false) => {
      handleCloseModal(device);
      handleOpenRemoveDeviceModal(
        device,
        removePrinterModalTypes.PRINTER_MODAL_REMOVING
      );
      if (!device) return;
      deleteDevice.makeApiCall({
        accountId: device.ownership?.accountId,
        deviceId: device.deviceId,
        ignoreWarning
      });
    },
    [deleteDevice, device, handleOpenRemoveDeviceModal, handleCloseModal]
  );

  const removePrinterHandler = useCallback(async () => {
    removePrinter();
  }, [removePrinter]);

  const removePrinterHandlerIgnoreWarning = useCallback(async () => {
    removePrinter(true);
  }, [removePrinter]);

  const closeAndGoToPlans = useCallback(() => {
    handleCloseModal();
    shell.navigation.push(PATHS.CHANGE_PLAN);
  }, [handleCloseModal, shell.navigation]);

  const refreshAndCloseWebView = useCallback(() => {
    dispatchJWebCloseEvent();
  }, []);

  const getFooter = useCallback(({ buttons, progress = false }) => {
    if (progress) return null;
    return (
      <ModalButtonGroup>
        {buttons
          .map((button, index) => ({
            ...button,
            id: `button_${index}`,
            appearance: index === 0 ? 'secondary' : 'primary'
          }))
          .map((button) => (
            <Button
              key={button.id}
              data-testid={button.id}
              onClick={button.onClick}
              appearance={button.appearance}
            >
              {button.text}
            </Button>
          ))}
      </ModalButtonGroup>
    );
  }, []);

  const getContent = useCallback(({ body, progress = false }) => {
    return (
      <>
        {progress && (
          <RemovePrinterProgress>
            <ProgressIndicator />
          </RemovePrinterProgress>
        )}
        {body
          .map((line, index) => ({
            text: line.text || line,
            dataTestId: line.dataTestId || undefined,
            id: `line_${index}`
          }))
          .map((line) => (
            <ModalLine
              key={line.id}
              className="caption"
              data-testid={line.dataTestId}
            >
              {line.text}
            </ModalLine>
          ))}
      </>
    );
  }, []);

  const modalMetadataRemoved = useMemo(() => {
    let body = [];
    let buttons = [];
    if (isJWebDesktopApp()) {
      body = [
        t('myPrinters.removePrinterModal.notInstInkNotLastHPPlus.body4', {
          printerName
        })
      ];
    } else if (isJWebiOSApp() || isJWebAndroidApp()) {
      body = [
        t('myPrinters.removePrinterModal.notInstInkNotLastHPPlus.body1', {
          printerName
        }),
        t('myPrinters.removePrinterModal.notInstInkNotLastHPPlus.body2')
      ];
      if (isJWebAndroidApp() && isJWebEventingPluginValid()) {
        buttons = [
          {
            text: t(
              'myPrinters.removePrinterModal.notInstInkNotLastHPPlus.primaryButton'
            ),
            onClick: refreshAndCloseWebView
          }
        ];
      }
    } else {
      body = [
        t('myPrinters.removePrinterModal.notInstInkNotLastHPPlus.body1', {
          printerName
        })
      ];
    }
    return {
      body,
      buttons: [
        {
          text: t(
            'myPrinters.removePrinterModal.notInstInkNotLastHPPlus.secondaryButton'
          ),
          onClick: handleCloseModalSuccess
        },
        ...buttons
      ].filter(Boolean)
    };
  }, [handleCloseModalSuccess, printerName, refreshAndCloseWebView, t]);

  const modalMetadata = useMemo(() => {
    switch (removePrinterModalType) {
      case removePrinterModalTypes.PRINTER_MODAL_OPEN:
        return {
          body: [
            {
              text: t('myPrinters.removePrinterModal.removePrinter.body2'),
              dataTestId: 'remove-printer-text-pp'
            },
            {
              text: t('myPrinters.removePrinterModal.removePrinter.body1'),
              dataTestId: 'remove-printer-text1-pp'
            }
          ],
          buttons: [
            {
              text: t(
                'myPrinters.removePrinterModal.notInstInkLastHPPlus.secondaryButton'
              ),
              onClick: () => {
                handleCloseModal();
                publishButtonClickedEvent(
                  analytics,
                  'Cancel',
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  ModalScreenPath,
                  'RemovePrinterModal'
                );
              }
            },
            {
              text: t(
                'myPrinters.removePrinterModal.notInstInkLastHPPlus.primaryButton'
              ),
              onClick: () => {
                removePrinterHandler();
                publishButtonClickedEvent(
                  analytics,
                  'Remove',
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  ModalScreenPath,
                  'RemovePrinterModal'
                );
              }
            }
          ]
        };
      case removePrinterModalTypes.PRINTER_MODAL_REMOVING:
        return {
          progress: true,
          body: [t('myPrinters.removePrinterModal.removing')],
          buttons: []
        };
      case removePrinterModalTypes.PRINTER_MODAL_FAILED:
        switch (removePrinterModalSubType) {
          case statusDeleteDeviceErrorMessage.HAS_SUBSCRIPTION:
            return {
              body: [
                t('myPrinters.removePrinterModal.instantInkSubscribed.body1', {
                  printerName
                }),
                t('myPrinters.removePrinterModal.instantInkSubscribed.body3')
              ],
              buttons: [
                {
                  text: t(
                    'myPrinters.removePrinterModal.instantInkSubscribed.secondaryButton'
                  ),
                  onClick: handleCloseModal
                },
                {
                  text: t(
                    'myPrinters.removePrinterModal.instantInkSubscribed.primaryButton'
                  ),
                  onClick: closeAndGoToPlans
                }
              ]
            };
          case statusDeleteDeviceErrorMessage.IS_LAST_HPPLUS:
            return {
              body: [
                t('myPrinters.removePrinterModal.notInstInkLastHPPlus.body1'),
                t('myPrinters.removePrinterModal.notInstInkLastHPPlus.body2')
              ],
              buttons: [
                {
                  text: t(
                    'myPrinters.removePrinterModal.notInstInkLastHPPlus.secondaryButton'
                  ),
                  onClick: handleCloseModal
                },
                {
                  text: t(
                    'myPrinters.removePrinterModal.notInstInkLastHPPlus.primaryButton'
                  ),
                  onClick: removePrinterHandlerIgnoreWarning
                }
              ]
            };
          default:
            return {
              body: [
                t('myPrinters.removePrinterModal.failed.body1'),
                t('myPrinters.removePrinterModal.failed.body2')
              ],
              buttons: [
                {
                  text: t(
                    'myPrinters.removePrinterModal.instantInkSubscribed.secondaryButton'
                  ),
                  onClick: handleCloseModal
                },
                {
                  text: t('myPrinters.removePrinterModal.failed.primaryButton'),
                  onClick: removePrinterHandler
                }
              ]
            };
        }
      case removePrinterModalTypes.PRINTER_MODAL_REMOVED:
        return modalMetadataRemoved;
    }
  }, [
    closeAndGoToPlans,
    handleCloseModal,
    modalMetadataRemoved,
    printerName,
    removePrinterHandler,
    removePrinterHandlerIgnoreWarning,
    removePrinterModalSubType,
    removePrinterModalType,
    t,
    analytics
  ]);

  return (
    <ModalContainer
      className="body"
      id="modal-id"
      show={openModal}
      onClose={() => handleCloseModal()}
      footer={getFooter(modalMetadata)}
    >
      {getContent(modalMetadata)}
    </ModalContainer>
  );
};

export default RemovePrinter;
