import React from 'react';
import PATHS from '../../constants/paths';
import { Container } from './styles';
import {
  useI18n,
  MFELoader,
  useRootContext,
  LoaderWidget
} from '@jarvis/react-portal-addons';
import useDeviceApiCall from 'src/hooks/useDeviceApiCall';
import useSecondChanceEligibilityApiCall from 'src/hooks/useSecondChanceEligibilityApiCall';
import useAccountInfoApiCall from 'src/hooks/useAccountInfoApiCall';

const ActivateHpPlusPage = () => {
  const { authProvider, stack, shell } = useRootContext();
  const { t } = useI18n();

  const searchParams = new URLSearchParams(shell.navigation.location.search);
  const deviceId = searchParams.get('cloudId');

  const printerInfo = useDeviceApiCall({
    stack,
    authProvider,
    deviceId
  });
  const secondChance = useSecondChanceEligibilityApiCall({
    authProvider,
    stack,
    deviceId: deviceId,
    platformIdentifier: printerInfo.data?.identity?.platformIdentifier
  });
  const accountInfo = useAccountInfoApiCall({ authProvider, stack });

  if (
    printerInfo.isFetching ||
    secondChance.isFetching ||
    accountInfo.isFetching
  ) {
    return <LoaderWidget fullScreen />;
  }

  const data = {
    printerName:
      printerInfo.data?.identity?.makeAndModel?.name ||
      t('myPrinters.defaultPrinterName'),
    printerImages: printerInfo.data?.images?.map((image) => image.url) || null,
    deviceEligibility: secondChance.data || {
      eligible: false,
      expires: 0
    },
    deviceSKU:
      printerInfo.data?.identity?.makeAndModel?.sku ||
      printerInfo.data?.identity?.makeAndModel?.number ||
      '',
    encryptedClientID: '',
    encriptedDeviceID: '',
    country: accountInfo.data?.regionId || 'Unavailable'
  };

  return (
    <Container>
      <MFELoader
        component="@jarvis/react-consumer-hp-plus-second-offer"
        stack={stack}
        properties={{
          paths: PATHS.MY_PRINTERS,
          data
        }}
      />
    </Container>
  );
};

export default ActivateHpPlusPage;
