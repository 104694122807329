import styled from 'styled-components';
import tokens from '@veneer/tokens';
const stylesIf =
  (propName, trueValue = '', falseValue = '') =>
  (props) =>
    props?.propName ? trueValue : falseValue;

export const Container = styled.div`
  display: grid;
  margin-top: 4px;

  grid-template-columns: ${stylesIf(
    'singleColumn',
    'auto',
    'minmax(min-content, 256px) 1fr'
  )};

  justify-content: ${stylesIf('singleColumn', 'center', 'left')};

  @media (max-width: ${tokens.smMin}) {
    grid-template-columns: ${stylesIf(
      'singleColumn',
      'auto',
      'minmax(min-content, 128px) 1fr'
    )};
  }
`;

export const CartridgeContainer = styled.div`
  align-self: center;
`;

export const SuppliesContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const RightArea = styled.div`
  display: grid;
  width: 100%;

  grid-row: 1;
  grid-column: 2;

  grid-template-columns: 1fr;
`;

export const LeftArea = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  max-width: 256px;
  margin: 20px 32px 20px 6px;

  margin: ${stylesIf('singleColumn', '20px 0 2px 0', '20px 32px 20px 6px')};

  grid-row: 1;
  grid-column: 1;

  @media (max-width: ${tokens.smMin}) {
    margin: ${stylesIf('singleColumn', '20px 0 2px 0', '20px 12px 20px -8px')};
  }
`;

export const RightFooter = styled.div`
  margin-bottom: 8px;
  margin-top: 20px;
`;

export const ServicesHeader = styled.div`
  margin-bottom: 12px;

  @media (max-width: ${tokens.smMin}) {
    font-size: 14px;
  }
`;

export const StatusHeader = styled.div`
  margin-bottom: 12px;
  @media (max-width: ${tokens.smMin}) {
    font-size: 14px;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${tokens.space3};
`;

export const Separator = styled.div`
  border-bottom: 1px solid #ebebeb;
  margin-top: 12px;
  margin-bottom: 20px;
`;
