import React from 'react';
import PropTypes from 'prop-types';
import { IconHelpBlue } from 'src/components/DeviceStatus/styles';
import { Coachmark } from 'src/shared/Coachmark';
import {
  CoachmarkContainer,
  Container,
  Icon,
  ServiceName,
  SideText,
  TitleContainer
} from './styles';

export const ServiceItem = ({
  icon,
  name,
  sideText,
  coachmarkText,
  ...rest
}) => {
  const dataTestId = rest['data-testid'] || null;
  return (
    <Container data-testId={dataTestId}>
      <Icon>
        <img
          src={icon}
          alt={name}
        />
      </Icon>
      <TitleContainer>
        <ServiceName className="label">{name}</ServiceName>
      </TitleContainer>
      <SideText className="caption">{sideText}</SideText>
      <CoachmarkContainer>
        <Coachmark
          title={coachmarkText}
          content={<IconHelpBlue />}
        />
      </CoachmarkContainer>
    </Container>
  );
};

ServiceItem.defaultProps = {
  sideText: ''
};

ServiceItem.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sideText: PropTypes.string,
  coachmarkText: PropTypes.string.isRequired
};

export default ServiceItem;
