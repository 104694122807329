import styled from 'styled-components';
import Tokens from '@veneer/tokens';
import { Select, Card, Modal } from '@veneer/core';

export const SelectContainer = styled(Select)`
  margin-bottom: 14px;
  margin-top: ${Tokens.space2};
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 14px;
  margin-top: ${Tokens.space3};
`;

export const HeaderText = styled.div`
  width: auto;
  :not(:first-child) {
    margin-left: ${Tokens.space2};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  > svg {
    flex-shrink: 0;
  }
`;

export const BodyContainer = styled.div``;

export const BodyItem = styled.div`
  :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const CardNoteContent = styled.div`
  display: flex;
  padding: 24px;
  align-items: top;
  > svg {
    flex-shrink: 0;
  }
`;

export const CardNoteText = styled.div`
  padding-left: 16px;
`;

export const CardNote = styled(Card)`
  && {
    margin-top: 24px;
    background-color: lightgray;
  }
`;
export const ModalContainer = styled(Modal)`
  .vn-modal--title {
    padding-bottom: ${Tokens.space5};
    margin: 0;
  }

  .vn-modal--footer {
    text-align: end;
    justify-content: right;
  }

  @media (min-width: ${Tokens.lgMin}) {
    .vn-modal--content {
      width: 600px;
    }
  }
`;
