import React from 'react';
import { Modal } from './Modal';
import { ProgressIndicator } from '@veneer/core';
import { BodyContainer, BodyItem, HeaderContainer, HeaderText } from './styles';
import { useI18n } from '@jarvis/react-portal-addons';

const TransferInProcessModal = ({
  openModal,
  onCloseModal,
  selectedOrganizationName
}) => {
  const { t } = useI18n();
  return (
    <Modal
      id="regular-modal"
      data-testid="transfer-inProcess-modal"
      onClose={onCloseModal}
      show={openModal}
    >
      <HeaderContainer>
        <ProgressIndicator
          appearance="circular"
          behavior="indeterminate"
        />
        <HeaderText className="title-small">
          {t('myPrinters.transferOwnershipModal.processingTranfer.header')}
        </HeaderText>
      </HeaderContainer>
      <BodyContainer>
        <BodyItem className="body">
          {t('myPrinters.transferOwnershipModal.processingTranfer.body1', {
            accountName: selectedOrganizationName
          })}
        </BodyItem>
      </BodyContainer>
    </Modal>
  );
};

export default TransferInProcessModal;
